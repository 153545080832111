@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Allan:wght@400;700&family=Work+Sans:wght@100;200;300;400;500;600;700&Mulish:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&family=Roboto:wght@100;300;400;500;700;900&display=swap'); */

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* Enable hardware acceleration for smoother scrolling on iOS */
  scroll-behavior: smooth; /* Enable smooth scrolling behavior */
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Force the scrollbar to be always visible */
/* body::-webkit-scrollbar-thumb:vertical {
  min-height: 20px;
} */

/* Force the scrollbar to be always visible when hovering over it */
/* body::-webkit-scrollbar-thumb:hover:vertical {
  min-height: 20px;
} */

code {
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* over all portal scroll */

div::-webkit-scrollbar {
  width: 8px;
  height: 9px;
  background-color: transparent;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  /* Adjust the border-radius as desired */
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

div::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Force the scrollbar to be always visible */
div::-webkit-scrollbar-thumb:vertical {
  min-height: 8vh;
}

/* Force the scrollbar to be always visible when hovering over it */
div::-webkit-scrollbar-thumb:hover:vertical {
  min-height: 8vh;
}

/* Force the horizontal scrollbar to be always visible */
div::-webkit-scrollbar-thumb:horizontal {
  min-width: 20px;
}

/* Force the scrollbar to be always visible when hovering over it */
div::-webkit-scrollbar-thumb:hover:horizontal {
  min-width: 20px;
}

/* over all portal scroll ends */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* All fonts are included here */

:root {
    /* --display-height: 100vh; */
    /* Default value, can be overridden by JavaScript */
    --bankwidget-height: var(--bankwidget-height);
    --dashBoardPage-height: var(--dashBoardPage-height);
    --upComingEvents-height: var(--upComingEvents-height);
}

/* common styling css start here */
body {
    padding: 0px;
    margin: 0px;
    font-family: 'Inter', sans-serif;
    background-color: #F3F4F6 !important;
    color: #111111;
}

.page-wrapper {
    background-color: #F3F4F6 !important;
}

p {
    padding: 0px;
    margin: 0px;
}

b {
    font-weight: 700 !important;
    color: #111111;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small {
    font-family: 'Inter', sans-serif !important;
}

h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
}

h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin: 0px;
}

.back-link-in-header {
    position: absolute;
    /* top: 28px; */
    top: 19px;
    right: 78px;
}

h6 {
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
}

.f-12-600-30 {
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
}

.f-10-400 {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
}

.f-12-400 {
    font-size: 12px;
    font-weight: 400;
}

.f-12-500 {
    font-size: 12px;
    font-weight: 500;
}

.f-16-400 {
    font-size: 16px;
    font-weight: 400;
}

.f-16-600 {
    font-size: 16px;
    font-weight: 600;
}

.f-20-700 {
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
}

.f-14-700 {
    font-size: 14px;
    font-weight: 700;
}

.f-26-700 {
    font-size: 26px;
    font-weight: 700;
}

.f-24-700 {
    font-size: 24px;
    font-weight: 700;
}

.f-14-500 {
    font-size: 14px;
    font-weight: 500;
}

.f-20-700 {
    font-size: 20px;
    font-weight: 700;
}

.f-14-700 {
    font-size: 14px;
    font-weight: 700;
}

p,
a {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
}

a:hover {
    color: #243B7F;
}


b {
    font-size: 14px;
    margin: 0px;
}

a {
    text-decoration: none !important;
}

small {
    font-size: 12px;
    font-weight: 400;
}

.popover {
    font-family: 'Inter', sans-serif !important;
    font-size: 12px;
}

.line-height-initial {
    line-height: normal !important;
    line-height: initial !important;
}

.line-height-22 {
    line-height: 22px !important;
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='email'] {
    height: 33px !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
}

input {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
}

input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
    /* Firefox */
}

input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
    /* Firefox */
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
    /* Firefox */
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #757A82 !important;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #757A82 !important;
}

.sentence-case {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: white !important;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.default-switch-button input {
    width: 22px;
    z-index: 2;
    cursor: pointer;
}

.default-switch-button.disable-toggle .custom-control-input:checked~.custom-control-label::before {
    background-color: #adb5bd !important;
    border-color: #adb5bd !important;
}

.cursor-pointer {
    cursor: pointer;
}

.full-height {
    height: 100% !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #243b7f !important;
    background-color: #243b7f !important;
}

.top-border {
    border-top: 1px solid #E6E8F0;
}

.red-status-pill,
.brownish-status-pill,
.green-status-pill,
.other-status-pill,
.blue-status-pill,
.purple-status-pill,
.default-status-pill {
    padding: 0 9px;
    display: inline-flex !important;
    align-items: center;
    border-radius: 30px;
    color: white !important;
    white-space: nowrap;
    position: relative;
}

.pill-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 20px;
}

.pill-text {
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 500;
}

.pill-img {
    margin: 0px 6px 0 0;
}

.task-history-table-dropdown table thead {
    position: static;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.bold-400 {
    font-weight: 400 !important;
}

.bold-500 {
    font-weight: 500 !important;
}

.bold-600 {
    font-weight: 600 !important;
}

.bold-700 {
    font-weight: 700 !important;
}

.f-14-600 {
    font-size: 14px;
    font-weight: 600;
}

.rotate-icon {
    animation: rotation 2s infinite linear;
}

.border-0 {
    border: 0;
}

.break-all-text {
    word-break: break-all;
}

.white-space-nowrap {
    white-space: nowrap;
}

.mw-250px {
    max-width: 250px;
}

.mw-150px {
    max-width: 150px;
}

.mw-400px {
    max-width: 400px;
}

.min-w-120px {
    min-width: 120px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.progress {
    background-color: #F3F4F6;
}

.title-with-underline {
    font-size: 16px;
    font-weight: 700;
    color: #243B7F;
    margin-bottom: 0px;
    display: inline-block;
    padding: 0 0 8px 0;
    border-bottom: 2px solid #243B7F;
}

.padding-20 {
    padding: 20px;
}

.p-16-20px {
    padding: 16px 20px;
}

.p-5-20px {
    padding: 5px 20px;
}

.p-10-20px {
    padding: 10px 20px;
}

.p-10-15px {
    padding: 10px 15px;
}

.p-12-20px {
    padding: 12px 20px;
}

.p-30-20px {
    padding: 30px 20px;
}

.mw-900px {
    max-width: 900px;
}

.breadcrumb-block a {
    color: #9E9E9E !important;
}

.breadcrumb-block a:hover {
    color: #243B7F !important;
}

.all-flooring-plans {
    position: relative;
}

.frozen-message {
    background-color: #A10F0F;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 14px;
    border-radius: 5px;
    width: 50%;
    max-width: 505px;
    z-index: 4;
}

.floor-car-main-wrapper .frozen-message {
    width: 100%;
    background-color: #D1D5DB;
    color: #111;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    text-align: center;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.frozen-message img {
    width: 20px;
    height: 20px;
    margin: 0 12px 0 0;
}

.dropdown-toggle {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    border: 0px;
    padding: 0px;
}

.box-shadow-none {
    box-shadow: none !important;
}

.dropdown-toggle::after {
    display: none;
}

.white-component-modal {
    border-radius: 8px;
    /* padding: 20px 5px; */
    padding-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tabs-with-table,
.white-component-modal {
    background: #fefefe;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    border-radius: 8px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.show-more-dropdown .dropdown-toggle span,
.show-more-dropdown .dropdown-toggle {
    color: #111 !important;
}

.dropdown-menu[aria-labelledby=dropdown-basic] {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    border-radius: 8px;
    border: 0;
}

.data-tooltip {
    display: inline-block;
}

.data-tooltip .table-responsive {
    border-radius: 8px !important;
    border: 0 !important;

}


.data-tooltip .dropdown-menu {
    padding: 0px;
}

.data-tooltip .dropdown-item {
    padding: 0px;
}

.data-tooltip table:hover {
    background-color: #fff;
}

.data-tooltip .dropdown-item thead {
    background-color: #F3F4F6;
}

.data-tooltip .dropdown-item thead th {
    font-weight: 550 !important;
}


.archive-tab-table .decisionnotes-in-table .data-tooltip .dropdown-item {
    padding: 5px 5px;
    max-width: 300px;
    display: block;
    word-break: break-word;
    white-space: normal;
}

.select-all-filter div[class$="-control"] div[class$="-multiValue"]:first-child {
    display: none;
}

.data-tooltip table {
    margin-bottom: 0px;
    border-radius: 8px;
    /* overflow: hidden; */
}

.data-tooltip a.dropdown-item:active {
    background-color: white !important;
}

.data-tooltip a.dropdown-item:hover {
    background-color: transparent !important;
}


.data-tooltip .default-table th:last-child,
.read-only-access .actionsItemsTable .data-tooltip th:last-child {
    display: table-cell !important;
}

/* .data-tooltip .dropdown-menu {
    max-height: 150px;
    overflow: auto !important;
} */
.data-tooltip .table-responsive {
    max-height: 175px;
    overflow: auto !important;
    /* padding: 0 1px 0 0; */
}



.complete-payment-btn {
    position: relative;
    background-color: transparent !important;
    padding: 0;
    border: 0;
}

.anchor-link {
    color: white !important;
    text-decoration: none !important;
}

.file-action-icons {
    width: 14px;
    cursor: pointer;
}

.complete-payment-btn .number-count-value, .number-count-value {
    min-width: 15px;
    padding: 0 3px;
    position: absolute;
    background-color: #A10F0F;
    font-size: 9px;
    line-height: 15px;
    border-radius: 20px;
    left: 18px;
    top: 6px;
    color: white;
}

.data-tooltip table th,
.data-tooltip table td,
.subrows-table table .data-tooltip table th,
.subrows-table table .data-tooltip table td,
.table-with-expanded-rows table .data-tooltip table th,
.table-with-expanded-rows table .data-tooltip table td {
    padding: 4px 16px !important;
    color: #111 !important;
    width: auto !important;
}

.table-with-expanded-rows table .data-tooltip table td:first-child,
.table-with-expanded-rows table .data-tooltip table th:first-child {
    padding: 4px 16px !important;

}

.subrows-table table .data-tooltip table th:first-child,
.subrows-table table .data-tooltip table td:first-child,
.payments-in-progress-table table .data-tooltip table .table-header-bg-color th:first-child,
.payments-in-progress-table table .data-tooltip table td:first-child {
    width: auto !important;
    max-width: unset !important;
    display: table-cell !important;
}

.payments-in-progress-table table .data-tooltip table {
    table-layout: auto;
}

.subrows-table table .data-tooltip table th:last-child,
.subrows-table table .data-tooltip table td:last-child {
    display: table-cell !important;
    vertical-align: middle;
}


.data-tooltip table td,
.default-label {
    font-size: 14px !important;
}

.global-search input {
    border-radius: 0px;
    font-size: 12px;
}

.advanced-filtered-item .rounded-lg {
    border-radius: 100% !important;
}

.data-tooltip .dropdown-toggle {
    font-size: 12px;
}

.data-tooltip table.default-table td:first-child,
.data-tooltip table.default-table td:nth-child(2),
.data-tooltip .subrows-table .default-table td:nth-child(2),
.data-tooltip .subrows-table .default-table td:nth-child(3) {
    width: auto;
}

.vin-search {
    border: 1px solid #D1D5DB;
}

.vin-search input {
    border: 0px;
    display: inline-block;
    width: 195px;
    font-size: 14px;
    padding: 0px 8px;
    border-right: 1px solid #D1D5DB;
    /* color: #757A82; */
    outline: none;
    flex-grow: 1;
}

.vin-search input[type="text"]:hover {
    background-color: rgba(17, 25, 39, 0.04);
}


.vin-search input[type="text"]:focus {
    background-color: transparent;
    border: 1px solid #243B7F;
    outline: none;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.05);
}

.search-by-vin-btn {
    border: 0px !important;
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E;
    text-transform: uppercase;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
    position: relative;
    transform: translate3d(0, 0, 0);
}

.search-by-vin-btn::after {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    background-color: #243B7F;
    transform: scale(10, 10);
    border-radius: 50%;
    opacity: 0;
    transition: transform .5s, opacity 1s;
}

.search-by-vin-btn:active::after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
}

.search-by-vin-btn span.vintext {
    color: #9E9E9E !important;
}

.default-pill {
    padding: 3px 8px;
    background-color: #9E9E9E;
    border-radius: 20px;
    display: inline-block;
    color: #fff;
    font-size: 12px;
}

.p-12-20px {
    padding: 12px 20px;
}

.create-users-policy-overview .section-heading-styles {
    margin: 0;
}

.create-users-policy-overview .default-fieldset .select-box-with-icon .dropdown-icon {
    width: 16px;
}

.create-users-policy-overview .default-table td:first-child,
.create-users-policy-overview .default-table th:first-child {
    border-right: 1px solid #E6E8F0;
}

.create-users-policy-overview .default-table {
    margin-bottom: 15px;
}

.create-users-policy-overview .table thead th,
.vehicle-eligibility-details.default-table table thead {
    border: 0;
}

.create-users-policy-overview .default-table.vehicle-eligibility-details {
    margin-bottom: 0;
}

.vehicle-eligibility-details.default-table table {
    margin: 5px 0 0 0;
}

.vehicle-eligibility-details.default-table thead th {
    padding: 4px 20px;
    border-color: #F3F4F6 !important;
}

.vehicle-eligibility-details thead th:first-child {
    min-width: 200px;
}

.create-user-dealership-policy .create-user-form-actions {
    padding: 25px 20px;
}

.create-user-dealership-policy .create-user-form-actions button {
    margin-right: 20px;
}

.create-user-dealership-policy input {
    min-height: 38px;
    font-size: 14px;
}

.create-user-dealership-policy .dealer-form-fields input {
    min-height: 0px;
}

.inventory-vin-search-block {
    max-width: 250px;
    margin: auto;
}

.inventory-vin-search-block .vin-search {
    max-width: 250px;
}

.inventory-search-parent .table_scroller {
    max-height: calc(var(--display-height) - calc(var(--info-height) + var(--header-height) + 316px)) !important;
}

.inventory-search-parent .tableExpanded .table-overflow-visible.table_scroller {
    max-height: none !important
}

.inventory-search-parent .top-ten-list-card:nth-child(2) {
    margin: 0 0 0 20px;
}

.inventory-search-parent .inventory-vin-search-block {
    max-width: 400px;
}

.inventory-search-parent .inventory-vin-search-block .vin-search {
    margin: 0 15px 0 0;
}

.inventory-search-parent .inventory-vin-search-block .small-btn {
    min-height: 33px;
    padding: 5px 16px;
}

.vin-search-block .small-btn {
    min-height: 33px;
}

.create-user-contract-details .form-group {
    margin: 0;
}

.create-user-contract-details tr td:first-child {
    width: 330px !important;
}

.checkout-page-table .checkout-page-table .default-date-picker {
    width: 110px;
}

.checkout-page-table td {
    vertical-align: top;
}

.alert-popup-logo {
    width: 150px;
    height: 150px;
    line-height: 150px;
    border-radius: 100%;
    margin: auto auto 20px;
    text-align: center;
    background-color: rgba(218, 165, 32, .1);
}

.external-connect-plaid {
    margin: 20px 0 0 0;
}

.fieldset-h-auto .default-fieldset {
    height: auto;
}

.fieldset-h-100 .default-fieldset {
    height: 100%;
}

.table-filter-static .table-filter-actions {
    position: static;
}

.dealer-list-popup-filter .table-filter-actions,
.dealer-score-popup-filter .table-filter-actions,
.table-filter-onHeader .table-filter-actions {
    top: -1px;
    top: 0px;
    z-index: 4;
}

.upcoming-events-chart .apexcharts-data-labels {
    transform: translateY(-6px) !important;
}

.default-document-item {
    border: 1px dashed #C5C5C5;
    padding: 15px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
}

.default-document-item input {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.default-document-item .default-document-label {
    display: flex;
    align-items: center;
    margin: 0;
}

.default-document-item .default-document-label img {
    margin-right: 8px;
}

.checkout-address-field div[class$="singleValue"] {
    text-overflow: ellipsis;
    width: 65%;
}

.dropdown-value-ellipse-95 div[class$="singleValue"] {
    width: 95%;
}

.count-in-popuptitle {
    margin-left: 4px;
    border-radius: 12px;
    background: #243B7F;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    min-width: 15px;
    display: inline-block;
    padding: 0 6px !important;
    font-family: "Inter", sans-serif;
    text-align: center;
    line-height: 20px;
}

.ach-no-parent-tabs>.nav.nav-tabs {
    display: none;
}

.ach-no-parent-tabs .table-filter-actions {
    z-index: 1;
}

.setup-wrapper .table-filter-actions {
    right: 187px;
}

.rotate-refresh {
    animation-name: spinning;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    cursor: pointer;
}

.datePicker-w-220 .datePickerIconStyles {
    max-width: 220px !important;
    min-width: 220px !important;
}

.default-table-actions-dd .dropdown-menu .dropdown-item>span:first-child,
.default-table-actions-dd .dropdown-menu .dropdown-item .viewApp-icon>span:first-child {
    min-width: 36px;
}

.default-table-actions-dd .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
}

.default-table-actions-dd .dropdown-menu .dropdown-item img {
    max-width: 100%;
}


.titleStatus-donut-chart .apexcharts-legend-marker {
    min-width: 10px !important;
    width: 10px !important;
    min-height: 10px !important;
    height: 10px !important;
    margin: 2px 3px 0 0;
}

.asset-tags-alignmet {
    margin: 0 0 0 48px;
}

.overflow-y-inherit {
    overflow-y: inherit !important;
}

.max-h-100 {
    max-height: 100%;
}

.mt-minus3 {
    margin: -3px 0 0 0;
}

.lender-inventory-table .addNotesFilter-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
}

.progress-bar-styles {
    flex-grow: 1;
    height: 8px;
    border-radius: 20px;
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Default banner stylings */
.default-banner-popup .banner-body,
.default-plaid-banner .banner-body {
    border: 1px solid #DEE2E6;
    border-radius: 5px 5px 0 0;
    margin: 0 0 25px 0;
}

.default-banner-popup h4,
.default-plaid-banner h4 {
    border-radius: 5px 5px 0 0;
    padding: 12px 18px;
    color: white;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
    font-size: 16px;
}

.default-banner-popup h2,
.default-plaid-banner h2 {
    padding: 10px 18px;
    color: #111111;
    font-weight: 700;
    line-height: 24.5px;
    font-size: 14px;
    margin: 0;
    border-bottom: 1px solid #DEE2E6;
}

.default-banner-popup .banner-body .banner-body-p,
.default-plaid-banner .banner-body .banner-body-p {
    padding: 10px 18px;
    font-size: 14px;
    line-height: 22px;
}

.default-banner-popup button,
.default-plaid-banner button {
    min-width: 105px;
}

.default-banner-popup .banner-request-docs {
    padding: 0 18px 18px;
}

.default-banner-popup .banner-request-docs>div {
    max-width: calc(33% - 8px);
    margin: 0 15px 15px 0;
}

.default-banner-popup .banner-request-docs>div:nth-child(3n) {
    margin-right: 0 !important;
}

.default-plaid-banner .plaid-button-row {
    margin: 0 18px 18px;
}

/* Default banner stylings ends */

.resend-otp-icon {
    width: 16px;
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.file-name-ellipse {
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    width: 100px;
    /* display: inline-block; */
}

.fedexLogo-inBtn {
    width: 35px;
    height: 20px;
    margin: 0 4px 0 0;
}

.attachments-details {
    width: 100%;
    max-width: 100%;
    word-wrap: wrap;
    word-break: break-all;
}

.attachments-details .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
    max-width: 100px;
}

.inventoryWidgetLender .inventoryShowDaysLabel {
    left: 516px;
}

.suppliersWidgetLender .default-table .table-filters .react-table-datalist-selector:focus-within {
    outline: unset !important;
    border-radius: 4px;
}

.suppliersWidgetLender .default-table .table-filters .react-table-datalist-selector>div:focus-within {
    outline: 2px solid #243B7F !important;
    border-radius: 4px;
}

.decisionnotes-in-table .notes-in-table-col {
    white-space: nowrap;
    max-width: 119px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-pagination-wrapper {
    flex: 1 1 100%;
}

.table-pagination-wrapper .cust-multi-select-box .select-box-with-icon,
.table-pagination-wrapper .default-fieldset .react-select-dealerlist [class*='-control'],
.table-pagination-wrapper .default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 22px !important;
    height: auto;
}

.table-pagination-wrapper .default-fieldset .react-select-dealerlist [class*="-indicator"] {
    padding: 0;
}

.table-pagination-wrapper .cust-multi-select-box.default-fieldset,
.table-pagination-wrapper .cust-multi-select-box.default-fieldset:focus-within {
    outline: 1px solid black !important;
    border: none !important;
    border-radius: 1px;
}

.table-pagination-wrapper .default-fieldset .select-box-with-icon .dropdown-icon {
    top: 3px;
    right: 4px;
    width: 16px;
}

.table-pagination-wrapper .cust-multi-select-box.default-fieldset .react-select-dealerlist [class$="-control"]>div:nth-child(2) {
    width: 22px;
}

.table-pagination-wrapper .cust-multi-select-box.default-fieldset .react-select-dealerlist [class$="-control"]>div:first-child {
    padding: 2px 4px;
}

.table-pagination-wrapper .default-fieldset .react-select-dealerlist input {
    width: 25px !important;
}

.table-pagination-wrapper div[class$="-menu"]>div {
    padding: 0;
}

.table-pagination-wrapper div[class$="-menu"]>div>div {
    padding: 3px 12px;
}

.table-pagination {
    border: 1px solid #E6E8F0;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
}

.table-pagination .default-btn::after,
.table-pagination .default-btn::before {
    display: none;
}

.table-pagination .default-btn {
    border-right: 1px solid #E6E8F0 !important;
    border-radius: 0;
    color: #111111 !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    min-width: auto;
    width: 35px;
    padding: 0;
}

.table-pagination .arrow-btns {
    color: #fff !important;
}

.table-pagination .serials {
    background-color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    width: auto;
    padding: 0 5px;
}

.transaction-summary-table {
    margin: 25px 0 0 0;
}

.transaction-summary-table .table-header-bg-color th {
    cursor: pointer;
}

.transaction-summary-table .lender-transaction-table {
    flex: 1 1 69.5% !important;
    max-width: 70% !important;
    display: flex;
    flex-direction: column;
    /* align-self: flex-start; */
}

.lender-transaction-table .tabDataCount {
    background-color: #243B7F !important;
}

.lender-transaction-table .table_scroller {
    max-height: unset !important;
    flex-grow: 1;
    /* flex-grow: unset; */
}

.menu-open .lender-transaction-table .table_scroller {
    max-height: 499px !important;
}

.transaction-summary-table .lender-transaction-summary {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(30% - 25px);
    max-width: calc(30% - 25px) !important;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
}

.lender-transaction-summary .shopping-cart-scroller {
    overflow: auto;
    min-height: 250px;
}

.approval-pending-text {
    font-size: 10px !important;
    color: #A67905 !important;
}

.dealer-utilization-subtitle .audit-progressbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.audit-progressbar-amount, .audit-progressbar-percentage {
    white-space: nowrap; 
}

@keyframes spinning {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

@media print {
    @page {
        size: auto;
        margin: 0;
        margin-top: 40px;
        margin-top: 20px;
    }

    body {
        padding: 40px;
    }

    title {
        display: none !important;
        visibility: hidden;
    }

    table th,
    table td {
        text-align: left !important;
        /* padding-left: 0px !important; */
        font-size: 14px;
    }

    table th {
        text-transform: uppercase;
        font-size: 12px;
    }

    .myinventory-table .col table tr td:first-child {
        max-width: 250px;
    }

    .vehicleDetailsInPrint p {
        margin-bottom: 5px;
    }

    .vehicleDetailsInPrint small {
        margin-bottom: 5px;
        display: block;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .success-page-header{
        display: block !important;
        width: 100%;
    }
    .success-page-header::after{
        clear: both;
        content: '';
        display: block;
    }
    .success-page-header .payment-success-details{
        float: right;
        width: 28%;
        text-align: right !important;
    }
    .success-page-header .payment-success-note{
        float: left;
        width: 60%;
    }
    .success-page-header .payment-success-details h6,
    .success-page-header .payment-success-details b{
        display: inline-block;
        text-align: right !important;
        margin: 0px !important;
        padding: 0px !important;
        width: 100% !important;
    }


}

.myinventory-table.d-none .row>.col-4:first-child,
.default-print-table.d-none .row>.col-4:first-child {
    min-height: 100px;
    display: flex;
    align-items: center;
}

.audit-inventory-print-table .contact-info {
    width: 175px !important;
}

.audit-inventory-print-table .loan-status {
    width: 20px !important;
}

.risk-details-body .default-fieldset-label {
    line-height: inherit;
}

.column-sort-icon {
    margin: -7px 0 -5px 3px;
}

.adv-filter-check-box {
    padding-top: 20px !important;
    margin-bottom: 0 !important;
}

.text-underline {
    text-decoration: underline;
}

.dropdownMenuOverflowVisible .dropdown-menu {
    overflow: visible !important;
    max-height: unset !important;
}

@media (min-width: 1600px) {

    .data-tooltip table td,
    .data-tooltip table th {
        font-size: 12px !important;
    }

    .banklist-selection-wrapper .external-bank-list .plaid-success-btn {
        min-width: 197px;
    }

    .banklist-selection-wrapper .external-bank-list .reconnect-plaid-btn {
        min-width: 173px;
    }
}

/* common styling css end here */

/* All popup styling start here */
.dealer-name-popup {
    color: #111111;
    font-weight: 700 !important;

}

.popup-title-text {
    flex-grow: 1;
}

.default-popup .modal-header {
    padding: 18px 20px;
    position: relative;
}

.default-popup .modal-content {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4.8px;
}

.default-popup .modal-body {
    padding: 20px;
    margin-bottom: 0px;
    font-family: 'Inter', sans-serif;
    color: #111111;
    font-size: 14px;
    font-weight: 400;
}

.tableInPopup.default-popup .modal-body {
    padding: 0px;
    margin-bottom: 0px;
}

.tableInPopup.default-popup .modal-body .table_scroller {
    /* max-height: 70vh; */
}

.default-popup .modal-header .close {
    padding: 3px;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px;
    z-index: 5;
    color: #fff;
    opacity: 1 !important;
    text-shadow: none;
    width: 14px;
    height: 14px;
}

.default-popup .modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    font-family: "Inter", sans-serif;
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: capitalize;
}

.popup-title-icon {
    font-size: 22px;
    height: 24px;
    width: 24px;
    margin-right: 13px;
    display: flex;
}

.popup-title-icon img,
.popup-title-icon svg {
    height: 24px;
    width: 24px;
}

.default-popup .modal-footer {
    border: 0px;
    padding: 0px 20px 20px;
    justify-content: flex-start;
}

.default-popup .modal-footer button,
.modal-action-btns button {
    margin-right: 16px;
}

.default-popup .modal-footer>* {
    margin: 0px;
}

.default-popup .modal-header .close span {
    font-size: 0px;
}

.default-popup .modal-header .popup-close-icon {
    margin-top: -2px;
    display: none;
}

.vehicle-name-in-popup,
.dealer-name-popup {
    margin-bottom: 4px;
}

.default-large-popup .modal-dialog {
    max-width: 1240px;
}

.default-medium-popup .modal-dialog {
    max-width: 850px;
}

.popup-600px .modal-dialog {
    max-width: 600px;
}

.default-small-popup .modal-dialog {
    max-width: 450px;
}


.default-large-popup .modal-dialog {
    max-width: 1240px;
}

.create-user-popup .form-group {
    margin: 0 0 15px 0;
}

/* .table-responsive, */
table {
    display: table;
    width: 100%;
}

.table-filters-inpopup-without-title {
    padding-top: 56px;
}


.default-body-wrapper {
    display: flex;
    flex-direction: column;
    /* height: 98vh;
    min-height: 98vh;
    overflow: hidden; */
}

.default-content-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.default-content-wrapper .tab-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

/* .icon-clicked {
    filter: brightness(0.4) grayscale(100%);
    color: rgb(108, 115, 127);
} */

.table-filter-actions .action-item.active-icon img,
.table-filter-actions .action-item:active img {
    -webkit-filter: brightness(0.4) grayscale(100%);
            filter: brightness(0.4) grayscale(100%);
    color: rgb(108, 115, 127);
}

.default-content-wrapper .tab-content>div {
    flex: 1 1;
    /* display: flex; */
    /* flex-direction: column; */
    /* overflow: auto; */
}

.tabs-with-table {
    flex: 1 1;
}

.dealerWidgetLender {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.table_scroller {
    overflow: auto;
}



.page-wrapper {
    height: 100vh;
}

.custom-table-responsive {
    max-height: 79vh;
    overflow: auto;
}

/* All popup styling end here */

/* All buttons css start here  */

.default-btn {
    border-radius: 8px;
    border: 0px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    min-width: 88px;
    min-height: 40px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px !important;
    outline: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.default-btn.btn-primary:after,
.default-btn.btn-outline-primary.setup-dealer-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #243b7f;
    border-radius: 8px;
    z-index: -2;
}

.default-btn.btn-primary:before,
.default-btn.btn-outline-primary.setup-dealer-btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #243B7F;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ;
    border-radius: 8px;
    z-index: -1;
}

.default-btn.btn-outline-secondary:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    z-index: -2;
}

.default-btn.btn-outline-secondary:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #757a82;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    z-index: -1;
}

.default-btn.btn-primary:hover,
.default-btn.btn-outline-secondary:hover,
.default-btn.btn-outline-primary.setup-dealer-btn:hover {
    color: #ffffff;
}

.default-btn.btn-primary:hover:before,
.default-btn.btn-outline-secondary:hover:before,
.default-btn.btn-outline-primary.setup-dealer-btn:hover:before {
    width: 100%;
}

.default-btn.btn-outline-secondary.no-bg-on-hover::after,
.default-btn.btn-outline-secondary.no-bg-on-hover::before,
.add-to-cart-btn::before,
.add-to-cart-btn::after {
    display: none;
}

.default-btn.btn-outline-secondary.no-bg-on-hover {
    color: #6c757d !important;
    min-height: 40px;
}


.default-gray-btn {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    min-width: 88px;
    min-height: 40px;
    background-color: #F3F4F6;
    color: #757A82 !important;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
}

.small-btn {
    font-size: 12px;
    padding: 5px 10px;
    line-height: 16px;
    min-height: auto;
    margin: 10px auto;
    margin-right: 0;
}

.default-rounded-btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    font-family: "Inter", sans-serif;
    background-color: #243B7F !important;
    color: #FFFFFF !important;
    padding: 2px 8px;
    border: 0.5px solid #243B7F !important;
    border-radius: 7px;
    outline: none !important;
    box-shadow: none !important;
    min-height: auto !important;
    min-width: auto !important;
}

.submitdetails-in-table .default-rounded-btn.default-btn:hover {
    background-color: #243B7F !important;
    border: 0.5px solid #243B7F !important;

}

.default-rounded-btn.default-btn::before,
.default-rounded-btn.default-btn::after {
    border-radius: 0;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: none;
}

.btn-primary.default-btn,
.btn-primary,
.btn-primary:hover,
.btn-primary:hover:active,
.btn-primary.default-btn:active,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #243B7F;
    border-color: #243B7F;
}

.btn-primary,
.btn-outline-secondary {
    border-radius: 8px;
}

.outline-button,
.border-9e9e9e,
.btn-outline-secondary,
.btn-outline-secondary:hover {
    border: 1px solid #757a82;
}

.default-btn.btn-success {
    background-color: #0E8074 !important;
    border-color: #0E8074;
}

.btn-outline-secondary:hover {
    background-color: #757a82;
}

.btn-outline-secondary.default-btn.no-bg-on-hover {
    background-color: transparent !important;
}

.full-width-btn {
    width: 100%;
}

.btn-outline-dotted.btn,
.btn-outline-dotted.btn:hover,
.btn-outline-dotted.btn:focus {
    border: 1px dashed #BDBDBD;
    font-weight: 700;
    font-size: 14px;
    color: #111111 !important;
    opacity: 1;
    display: flex;
    align-items: center;
    background-color: #fff !important;
    padding: 10px;
}

.btn-outline-dotted.btn img {
    margin: 0 10px 0 0;
}

.pay-btn-in-table {
    background-color: #243B7F !important;
    height: 24px;
    padding: 0px 8px;
    border-radius: 22px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border: 0px;
    outline: none !important;
    box-shadow: none !important;
}

.pay-btn-in-table:hover {
    background-color: #243B7F !important;
}

.pay-btn-in-table svg {
    margin: -2px 0 0 0;
}

.track-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    padding: 2px 8px;
    width: 60px;
    background: #243b7f !important;
    border: 0px;
    border-radius: 22px;
    outline: none !important;
    box-shadow: none !important;
    display: inline;
}

.pay-remove-btn-in-table {
    width: 62px;
    height: 24px;
    border: 1px solid #243B7F !important;
    text-align: center;
    line-height: 24px;
    background-color: transparent !important;
    border-radius: 22px;
    color: #243B7F !important;
    padding: 0px;
    font-size: 12px;
    outline: none !important;
    box-shadow: none !important;
}

.add-to-cart-btn {
    position: relative;
    min-width: auto !important;
    background-color: transparent !important;
    border: 0px !important;
    padding: 0px !important;
    overflow: visible;
    display: flex;
}

.add-to-cart-btn a {
    color: #fff !important;
}

.add-to-cart-btn .number-count-value {
    position: absolute;
    background: #A10F0F;
    color: #fff !important;
    left: 12px;
    top: -5px;
}

.default-btn.btn-outline-primary {
    border: 1px solid #243B7F;
    color: #243B7F;
    background-color: white;
}

.default-btn.btn-outline-primary:hover,
.default-btn.btn-outline-primary:active:not(:disabled):not(.disabled):active {
    background-color: #243B7F;
    border-color: #243B7F;
    color: #fff;
}

.btn-disabled {
    opacity: .65 !important;
}

.submitdetails-in-table button {
    min-width: 83px !important;
}

button.plaid-success-btn {
    min-width: 185px;
}

/* All buttons css end here  */


/* Default input file css start here */
.default-document-item {
    border: 1px dashed #C5C5C5;
    padding: 15px;
    position: relative;
    cursor: pointer;
}

.default-document-item input {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.default-document-item .default-document-label {
    display: flex;
    align-items: center;
}

.default-document-item .default-document-label img {
    margin-right: 8px;
}

.stick-label-button {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    display: inline-block;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 30px;
    color: white !important;
    background-color: #243B7F !important;
    border: 0px !important;
    outline: 0px !important;
    box-shadow: none !important;
    cursor: pointer;
    font-weight: 600;
}

.custom-error-message {
    color: #A10F0F !important;
    font-weight: 500 !important;
    font-size: 12px;
}

.custom-error-message.brownish-status-color {
    color: #A67905 !important;
}

/* Default input file css end here */

/* Asset tags css end here */
.vehicle-asset-tags-wrapper {
    /* display: block; */
}

.asset-tag-item {
    border-radius: 20px;
    display: inline-block;
    color: #fff;
    margin-right: 5px;
    margin-top: 5px;
    line-height: 20px;
}

.asset-tag-item small {
    color: #fff !important;
    font-size: 12px;
    line-height: 20px;
}

.asset-tag-item .dropdown-item:active {
    background-color: white !important;
}

button {
    box-shadow: none !important;
}

/* Asset tags css end here */

/*  All default table css start here  */

.default-table {
    display: table;
    margin-bottom: 0px;
    width: 100%;
}

.semi-heading-intable {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #111111 !important;
}

table.default-table tr.disable-row {
    background-color: rgb(243 244 246) !important;
}

.subrows-table .default-table th:first-child,
.subrows-table .default-table td:first-child {
    padding-left: 3px !important;
    padding-right: 0 !important;
    margin-right: 0px;
    width: 41px !important;
    display: block;
}

.subrows-table .default-table td:nth-child(2) {
    padding-left: 0px;
}

.no-data-available {
    max-width: 100% !important;
    min-width: auto !important;
    text-align: center !important;
    width: 100% !important;
    display: table-cell !important;
    padding: 30px !important;
    border-bottom: 1px solid #E6E8F0 !important;
    font-size: 16px !important;
    font-weight: 500;
    color: #9E9E9E;
}

.default-table td,
.default-table .table-header-bg-color th {
    text-align: left;
    padding: 20px;
}

.default-table th {
    text-transform: uppercase;
}

.default-table thead {
    /* border-top: 1px solid #E6E8F0; */
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 3;
}

.default-table .table-header-bg-color th {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.5px;
    line-height: 22px;
}

.default-table td small {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    display: block;
    /* line-height: 20px; */
}

.default-table td,
.current-month-subscription-table input {
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.actionsItemsTable .default-table td:last-child,
.actionsItemsTable .default-table th:last-child,
.read-only-access .vehicle-registry-report-table .actionsItemsTable .default-table td:last-child,
.read-only-access .vehicle-registry-report-table .actionsItemsTable .default-table th:last-child {
    max-width: 81px;
    min-width: 81px;
    margin-left: auto;
    margin-right: 0px;
    display: block;
}

.read-only-access .actionsItemsTable .default-table td:last-child,
.read-only-access .actionsItemsTable .default-table th:last-child {
    max-width: unset;
    min-width: unset;
    margin-left: unset;
    margin-right: unset;
    display: table-cell;
}

.supplier-dealer-list-table .default-table th:last-child,
.supplier-dealer-list-table .default-table td:last-child {
    max-width: 150px;
    min-width: 150px;
    margin-left: auto;
    margin-right: 0px;
    display: block;
}

.supplier-dealer-list-table .default-table td .cs-btn {
    min-width: 125px;
    max-width: 125px;
    background-color: #243B7F;
    color: #fff;
}


.data-tooltip td:last-child,
.data-tooltip th:last-child {
    min-width: auto !important;
    max-width: 100% !important;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:focus,
.table-hover tbody tr:active {
    background-color: #F3F4F6 !important;
}

.default-table-actions-dd button,
.default-table-actions-dd button:focus,
.default-table-actions-dd button:hover,
.default-table-actions-dd button:active,
.default-table-actions-dd .btn-success:not(:disabled):not(.disabled).active,
.default-table-actions-dd .btn-success:not(:disabled):not(.disabled):active,
.default-table-actions-dd.show>.btn-success.dropdown-toggle {
    background-color: transparent !important;
    border: 0px;
    padding: 0px;
    color: #9E9E9E !important;
}

.disabled-dd-action {
    pointer-events: none;
}

.default-table-actions-dd .dropdown-item.disabled-dd-action * {
    color: #9E9E9E;
}

.default-table-actions-dd a {
    color: #111111;
}

.default-table-actions-dd .dropdown-toggle::after {
    display: none;
}

.default-table-actions-dd .dropdown-menu {
    background: #FFFFFF;
    border: 0px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0px;
    max-height: 250px;
    min-width: 240px;
    overflow-y: auto;
    /* position: fixed !important; */
}

.default-table-actions-dd .dropdown-submenu-container {
    position: relative;
}

.default-table-actions-dd .dropdown-submenu-container .dropdown-menu {
    position: absolute !important;
    left: -235px;
    top: 0px;
    max-height: 180px;
    overflow-y: auto;
}

.lender-dealer-documents .default-table-actions-dd .dropdown-submenu-container .dropdown-menu {
    max-height: 110px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #243B7F !important;
    color: #fff !important;
}

.default-table-actions-dd .dropdown-item,
.default-table-actions-dd .dropdown-item:focus,
.default-table-actions-dd .dropdown-item:active {
    color: #111111 !important;
}

.default-table-actions-dd .dropdown-item {
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    border: 0;
    outline: 0;
    box-shadow: none;
    border-bottom: 1px solid #E6E8F0;
    background-color: transparent !important;
    min-width: 235px;
}

.default-table-actions-dd .dropdown-item.subdropdown-parent-item {
    border-bottom: 0px;
}

.default-table-actions-dd .dropdown-item:hover {
    background-color: #F3F4F6 !important;
}

.default-table-actions-dd .dropdown-item.dropdown-submenu,
.default-table-actions-dd .dropdown-item.dropdown-submenu:hover {
    padding: 0px;
}

.default-table-actions-dd .subdropdown-parent-item .dropdown-submenu.dropdown-toggle,
.default-table-actions-dd .subdropdown-parent-item .dropdown-submenu.dropdown-toggle:hover {
    padding: 16px;
}

.default-table-actions-dd img,
.default-table-actions-dd svg {
    margin: 0 9px 0 0;
    width: 22px;
    padding: 3px;
}

.default-table .default-table-actions-dd img,
.default-table .default-table-actions-dd svg {
    height: 22px;
}

.default-table-actions-dd svg {
    margin: 0 9px 0 0;
    color: #9E9E9E;
}

.show-more-dropdown .default-table-actions-dd .dropdown-menu {
    max-height: 100px;
}

.show-more-dropdown .default-table-actions-dd .dropdown-item,
.show-more-dropdown .default-table-actions-dd .dropdown-item:active,
.show-more-dropdown .default-table-actions-dd .dropdown-item:focus,
.show-more-dropdown .default-table-actions-dd .dropdown-item:hover {
    max-height: 100px;
    min-width: 100px;
    padding: 7px;
    font-size: 12px;
}

.long-report-table {
    margin: 0px;
}

.long-report-table .col {
    padding: 0px;
}

.servcing-action-dropdown.default-table-actions-dd .dropdown-item {
    position: relative;
    padding-left: 32px !important;
}

.servcing-action-dropdown.default-table-actions-dd .dropdown-item img,
.servcing-action-dropdown.default-table-actions-dd .dropdown-item svg {
    position: absolute;
    left: 5px;
    top: 15px;
}

.default-table-actions-dd.dropdownMenuOverflowVisible .subdropdown-parent-item .dropdown-submenu.dropdown-toggle,
.default-table-actions-dd.dropdownMenuOverflowVisible .subdropdown-parent-item .dropdown-submenu.dropdown-toggle:hover,
.default-table-actions-dd.dropdownMenuOverflowVisible .dropdown-item:hover,
.default-table-actions-dd.dropdownMenuOverflowVisible .dropdown-item:focus,
.default-table-actions-dd.dropdownMenuOverflowVisible .dropdown-item {
    padding: 13px 10px;
}

.default-table .react-table-datalist-selector {
    max-width: 250px;
    min-width: 140px;
}

.partner-table-filters .table-filter-actions {
    z-index: 1;
    top: -53px;
}

.lender-payments-page-wrapper .partner-table-filters .table-filter-actions {
    z-index: 4;
}
.credit-scheduled-table-wrapper .table-filter-actions {
    top: -56px;
}
.table-filters input {
    width: 100%;
    background: #fff;
    border: 0px;
    font-size: 14px;
    border-radius: 5px;
    max-width: 250px !important;
    height: 32px;
    padding: 6px 10px;
    outline: none !important;
    font-family: 'Inter', sans-serif;
}

.default-table .table-filters input:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}


.table-filters input:focus,
.lender-dealer-documents input:focus {
    background-color: #fff;
    outline: 2px solid #243B7F !important;
}

.table-filters .react-table-datalist-selector input:focus {
    outline: none !important;
}

.table-filters .react-table-datalist-selector input:hover {
    background-color: transparent !important;
}

.table-filters fieldset:focus-within {
    border: 0 !important;
    outline: 2px solid #243b7f !important;
}

.table-filters .react-datepicker-wrapper fieldset input[type="text"]:hover,
.lender-dealer-documents input:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}

.table-filters .react-datepicker-wrapper fieldset input[type="text"]:focus {
    background-color: #fff;
    border: 2px solid #243B7F !important;
    outline: none;
}


.table-filters .css-yk16xz-control {
    border: 0px;
}

.table-filters .default-fieldset {
    border: 0;
}

.table-filters .filtes-calenderIcon {
    min-width: 145px;
    max-width: 145px;
}

.advanced-filtered-item,
.risk-total-record-pill {
    padding: 6px 8px;
    background-color: transparent;
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    margin: 0 18px 7px 0;
    border: 1px solid rgb(229, 231, 235)
}

.alerts-tags-items {
    padding: 2px 8px;
    background: #F2F2F2;
    border-radius: 19px;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 12px;
    font-size: 10px;
    color: #111;
}

.advancedSearchFilters {
    padding: 30px 15px !important;
    margin: 0;
    align-items: flex-start;
}

.advancedSearchFilters .default-fieldset {
    margin: 0px 0 0px 0;
}

.date-filters-first {
    margin: 0px;
}

.advancedSearchFilters>div {
    padding: 0;
    margin: 0px 30px 30px 0;
    max-width: 31.5%;
}

.menu-open .advancedSearchFilters>div {
    padding: 0;
    margin: 0px 30px 30px 0;
    max-width: 30.5%;
}

.advancedSearchFilters>div:nth-child(3n) {
    margin-right: 0;
}

.advancedSearchFilters>div.advance-filters-actions {
    max-width: 100%;
    margin-bottom: 0;
}

.advancedSearchFilters .advance-filters-actions button {
    margin: 0 15px 0 0;
}

.payments-in-progress-table tr.subrows td:last-child img,
.payments-in-progress-table tr.subrows td:last-child span,
.subrows td:nth-child(2) p,
.subrows td:nth-child(2) div,
.subrows td:nth-child(2) span {
    display: none !important;
}

/*Start of React Table Data Selector Code height 32px*/
.riskIndicator {
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    align-self: flex-start !important;
    margin: 5px 4px 0 0px;
    display: inline-block;
    border-radius: 10px;
}

.riskIndicator.align-self-center {
    align-self: center !important;
}

.react-table-datalist-selector {
    max-width: 250px;
    min-width: 130px;
}

.react-table-datalist-selector .css-yk16xz-control,
.react-table-datalist-selector .css-1pahdxg-control,
.react-table-datalist-selector .css-2b097c-container {
    min-height: 32px;
}

.react-table-datalist-selector .css-yk16xz-control input,
.react-table-datalist-selector .css-1pahdxg-control input,
.react-table-datalist-selector .css-2b097c-container input {
    padding: 0px 5px !important;
    height: auto !important;
    margin: 0 0 0 -6px !important;
}

.react-table-datalist-selector .css-1pahdxg-control [class$="-placeholder"],
.react-table-datalist-selector .css-yk16xz-control [class$="-placeholder"],
.react-table-datalist-selector .css-2b097c-container [class$="-placeholder"] {
    padding: 0px 5px !important;
    height: auto !important;
    margin: auto !important;
    font-weight: 400;
    color: #757A82;
}

.react-table-datalist-selector .css-yk16xz-control [class*="-indicatorContainer"],
.react-table-datalist-selector .css-1pahdxg-control [class*="-indicatorContainer"] {
    padding: 0px 6px !important;
    cursor: pointer;
}

.menu-open .react-table-datalist-selector .css-yk16xz-control [class*="-indicatorContainer"],
.menu-open .react-table-datalist-selector .css-1pahdxg-control [class*="-indicatorContainer"] {
    padding: 0px !important;
}

.react-table-datalist-selector .css-yk16xz-control>div,
.react-table-datalist-selector .css-1pahdxg-control>div {
    padding: 0px 6px !important;
}


.react-table-datalist-selector [class$="-control"] {
    border-color: transparent !important;
    box-shadow: none;
}

.react-table-datalist-selector [class$="-control"]:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}

/* 
.react-table-datalist-selector div[class*='xg-control'] {
    background-color: #fff;
    border: 2px solid #243B7F !important;
    outline: none;
} */

.react-table-datalist-selector div[class*='xg-control']:focus-within {
    background-color: #fff;
    /* border: 2px solid #243B7F !important; */
    outline: none;
}



.lenderReportsPageWrapper .react-table-datalist-selector [class*="-indicatorContainer"],
.lenderAuditsTable .react-table-datalist-selector [class*="-indicatorContainer"] {
    padding: 0px !important;
}

/*End of React Table Data Selector Code height 32px*/



/*  Checkout page table css start here  */

.checkout-payment-lines {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    font-size: 14px;
    padding: 20px;
}

.checkout-payment-lines label {
    margin: 0px;
    padding: 0px;
    font-weight: 700;
}

.checkout-payment-lines label {
    flex-basis: 0;
    flex-grow: 1;
}

.checkout-bank-details p {
    margin-bottom: 10px;
    font-size: 14px;
}

.checkout-bank-details span {
    min-width: 150px;
    display: inline-block;
}

/*  Checkout page table css end here  */

/*  All default table css end here  */


/*  All default tabs css start here  */


.nav-tabs .nav-link.active,
.nav-pills .nav-link.active {
    background: none;
    color: #243B7F;
    font-weight: 700;
    border: 0px;
    position: relative;
}


.nav-tabs .nav-link,
.nav-pills .nav-link {
    padding: 0px;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #757A82;
    border: 0px;
}

@keyframes button_animation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    30% {
        opacity: 1;
    }

    80% {
        transform: translate(-50%, -50%) scale(0.8);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
    }
}


.tabs-with-table .nav-tabs .nav-link.active .tabName::after,
.primary-level-tabs.nav-tabs .nav-link.active span::after {
    content: "";
    width: 100%;
    height: 2px;
    background: #243B7F;
    border-radius: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
}

.tabs-with-table .nav-pills .nav-link.active:after,
.tabs-with-table .nav-tabs .nav-link.active::after {
    display: none;
}

.tabs-with-table .nav-tabs .nav-link:first-child,
.primary-level-tabs.nav-tabs .nav-link:first-child {
    padding-left: 18px !important;
}

.tabs-with-table .nav-tabs .nav-link .tabName,
.primary-level-tabs.nav-tabs .nav-link.active span {
    position: relative;
    padding-bottom: 4px !important;
    border-radius: 3px;
}

.tabs-with-table .nav-tabs {
    border-color: #E6E8F0;
    padding: 20px 0px;
}

.tabDataCount {
    margin-left: 4px;
    border-radius: 12px;
    background: #757A82;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    min-width: 15px;
    display: inline-block;
    padding: 0px 6px !important;
    font-family: 'Inter', sans-serif;
    max-height: 15px;
    text-align: center;
    line-height: 15px;
}

.primary-level-tabs {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E6E8F0;
}

/*  All default tabs css end here  */


.policy-inputgroup-parent .default-fieldset {
    width: 100%;
}

.policy-inputgroup-parent .input-group-text {
    position: absolute;
    right: 0px;
    top: 0;
    max-height: 40px;
    min-height: 40px;
}

.view-application-policy .default-fieldset {
    margin: 0;
}

/*  All default tabs css end here  */

.audit-status-dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: block;
    margin-right: 3px;
    background-color: #A67905;
}

.audit-progress-bar {
    max-width: 80%;
    margin-right: 20px;
}

.audit-verified-pill,
.audit-unverified-pill {
    width: 37px;
    height: 20px;
    background: #0E8074;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    display: block;
    font-size: 12px;
    line-height: 20px;
}

.audit-unverified-pill {
    background: #A10F0F;
}

.maroon-text {
    color: #A10F0F;
}

table,
table input {
    color: #111 !important;
}

table.default-table input,
table.default-table textarea,
table.default-table select {
    border-radius: 8px;
    border-color: #E0E0E0;
    outline: none;
    box-shadow: none;
    border: 1px solid #e0e0e0;
}

table.default-table input {
    padding: 10px 9px !important;
}

.default-table td,
.default-table .table-header-bg-color th,
.scheduler-inner-jsx td {
    text-align: left;
    padding: 10px;
}

.default-table .table-header-bg-color th {
    border: 0px;
    vertical-align: top;
}

.default-table td {
    border-top: 0px;
    border-bottom: 0px;
}

.default-table tr,
.border-bottom-E6E8F0 {
    border-bottom: 1px solid #E6E8F0;
}

.table-header-bg-color tr {
    border-bottom: 0px solid #E6E8F0;
    position: relative;
}

.table-header-bg-color tr::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #E6E8F0;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
}

.default-table .hide-border-bottom {
    border-bottom: none !important;
}

.border-E6E8F0 {
    border: 1px solid #E6E8F0;
}

.border-right-9E9E9E {
    border-right: 1px solid #9E9E9E;
}

.border-left-9E9E9E {
    border-left: 1px solid #9E9E9E;
}

.border-top-E9EBF2 {
    border-top: 1px solid #E9EBF2;
}

.default-table td:first-child,
.default-table .table-header-bg-color th:first-child {
    padding-left: 20px;
}

.default-table .cs-rsm-dropdown {
    font-size: 14px;
}

.dateandtime-in-table {
    /* min-width: 140px;
    max-width: 140px; */
    color: #757A82 !important;
}

.dateonly-in-table {
    min-width: 85px;
    /* max-width: 85px; */
    color: #757A82 !important;
}

.subrows .expanded-vehiclename-with-icon,
.subrows .subrow-hide-col {
    display: none;
}


/* custom column width changes start here  */
.audits-upcoming-page-table .dealer-name-wrapper {
    min-width: auto;
    max-width: 100%;
}

/* custom column width changes end here  */


.subrows,
.checkout-page-table .checkout-page-table,
.success-page-table .inner-table table {
    border-left: 2px solid #243B7F;
}

.checkout-page-table .checkout-page-table thead tr::after {
    left: 2px;
}

.innertable-tow .tabs-with-table {
    border-left: 2px solid #243B7F;
    border-radius: 0px;
}

.innertable-tow>td {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.table-hover tbody tr.innertable-tow:hover {
    background-color: transparent !important;
}

.expandview-table {
    margin-top: -100%;
    transition: margin-top 1s linear;
    -webkit-transition: margin-top 1s linear;
    -moz-transition: margin-top 1s linear;
    -ms-transition: margin-top 1s linear;
    -o-transition: margin-top 1s linear;
    transition-timing-function: linear;
    transition-delay: 0s;
    transition-duration: 1s;
    transition-property: margin-top;
}

.isopen.expandview-table {
    margin-top: 0;
    border-left: 2px solid #243B7F;
}

/* CSS for Assign Funding Request Component */
.loantype-item {
    display: flex;
    align-items: center;
    margin: 0 12px 15px 0px;
}

/* CSS for Assign Funding Request Component Ends here */

.default-popup .vehicleHistoryShowMoreModalPopup {
    margin: 32px -20px 0 -20px !important;
}

.default-popup .upload-audit-data-table {
    margin: 20px -20px 0 -20px !important;
}

.upload-wrapper {
    display: inline-block;
}

.w-49 {
    width: 49% !important;
}

.three-fields-row>div {
    min-width: 31.5%;
    max-width: 32%;
    flex: 1 1;
    padding: 0 !important;
    margin: 0 15px 15px 0;
}

.three-fields-row>div:nth-child(3n) {
    margin-right: 0 !important;
}

.two-fields-row>div {
    min-width: 48%;
    max-width: 49%;
    flex: 1 1;
    padding: 0 !important;
    margin: 0 15px 15px 0;
}

.two-fields-row>div:nth-child(2n),
.two-fields-row>div:last-child {
    margin-right: 0 !important;
}

.four-fields-row>div {
    min-width: 23.5%;
    max-width: 24%;
    flex: 1 1 23.5%;
    padding: 0 !important;
    margin: 0 15px 15px 0;
}

.four-fields-row>div:nth-child(4n) {
    margin-right: 0 !important;
}

.dealer-principal-row>div:nth-last-child(-n+2),
.dealer-info-row>div:nth-last-child(-n+2) {
    margin-bottom: 0;
}



.auctionAccessTab .auction-access-show-pill,
.bos-bank-showdays .auction-access-show-pill {
    position: absolute;
    top: 19px;
    left: 840px;
}

.create-file-btn {
    position: absolute;
    right: 125px;
    top: -116px;
}

.achCreditDebitRefresh.create-file-btn.advanced-filters-open {
    top: -293px;
}

.lenderReportsPage .dealer-name-wrapper {
    min-width: 150px;
}

.lenderReportsPage .dealer-address-wrapper {
    min-width: 175px;
}

.lenderReportsPage .vehicleDetails {
    min-width: 110px;
}

.table-vehicle-logo {
    min-width: 38px;
    max-width: 38px;
    min-height: 38px;
    max-height: 38px;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.08);
    border: 1px solid #FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin: 0 10px 0 0;
}

.table-vehicle-logo img {
    width: 24px;
    height: 24px;
}

.vehicle-logo-in-table {
    display: flex;
    align-items: center;
}

.missing-alert-para {
    line-height: 20px;
}

.missing-alert-para a {
    color: #243B7F !important;
}

p.color-9E9E9E-imp {
    color: #9E9E9E !important;
}

.notification-info.task-only {
    flex: 0 0 60%;
    position: relative;
}

.myinventory-table-header {
    padding: 5px 20px;
    margin: 0;
    align-items: center;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #d1d5db;
}

select option:hover,
select option::selection {
    background: #243B7F;
    color: #fff;
}

.disable-content .frozen-disabled>div {
    position: relative;
}

.disable-content .frozen-disabled>div::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(240, 240, 240, 0.6);
    z-index: 3;
    border-radius: 8px;
}

.inventory-action-items {
    min-width: 70px;
}

.inventory-action-items img {
    width: 18px !important;
}

.fedexPrintLabelTable td:first-child,
.fedexPrintLabelTable .table-header-bg-color th:first-child {
    width: 45px;
}

.fedexPrintLabelTable.default-table .table-header-bg-color th,
.fedexPrintLabelTable.default-table td {
    vertical-align: middle;
}

.fedexPrintLabelTable.default-table th:first-child .default-fieldset,
.fedexPrintLabelTable.default-table td:first-child .default-fieldset {
    margin: 0;
}

.alert-count {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #A10F0F;
    display: inline-block;
    text-align: center;
    margin: 0 0 0 10px;
    line-height: 25px;
}



/* .table-filter-actions .action-item .table-filter-funding-icon {
    width: 18px;
} */

.first-col-check-box-table th:first-child,
.first-col-check-box-table td:first-child,
.banner-dealer-list table td:first-child,
.banner-dealer-list table th:first-child {
    width: 47px !important;
    max-width: 47px !important;
    padding: 10px 11px 10px 22px !important;
}

.fedexPrintLabelTable td:first-child .input-label-wrap,
.fedexPrintLabelTable .table-header-bg-color th:first-child .input-label-wrap {
    width: 18px;
    height: 18px;
}

.table-with-expanded-rows th:first-child,
.table-with-expanded-rows td:first-child {
    /* width: 34px !important; */
    /* padding: 10px !important; */
    padding-left: 3px !important;
    padding-right: 0;
    margin-right: -12px;
    width: 28px;
    max-width: 28px;
    display: block;
}

.table-with-expanded-rows table table th:first-child,
.table-with-expanded-rows table table td:first-child,
.table-with-expanded-rows table .inner-table {
    padding: 10px 10px 10px 25px !important;
    width: auto;
}

.table-with-expanded-rows th:first-child.expanded-checkout-inner-table,
.table-with-expanded-rows td:first-child.expanded-checkout-inner-table,
.table-with-expanded-rows th:first-child.inner-table,
.table-with-expanded-rows td:first-child.inner-table,
.table-with-expanded-rows td.inner-table th:first-child,
.table-with-expanded-rows td.inner-table td:first-child {
    display: table-cell;
    width: auto;
    margin-right: 0;
    max-width: unset;
}

.table-with-expanded-rows.expanded-checkout-table .inner-table {
    padding: 0 !important;
}

.expanded-checkout-table .default-fieldset.default-checkbox {
    margin: 0;
}

.expanded-checkout-table .table-header-bg-color th legend.default-fieldset-label {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.5px;
    line-height: 22px;
}

.first-col-check-box-table td:first-child .input-label-wrap,
.first-col-check-box-table th:first-child .input-label-wrap {
    display: block;
    height: 18px;
    width: 18px;
}

.clipboard-add-icon {
    height: 24px;
}

.prinTableHeading {
    left: 50%;
    transform: translateX(-50%);
}

/* .shared-notes-table .table-overflow-visible,
.onboarding-tab-table .table-overflow-visible,
.archive-tab-table .table-overflow-visible {
    overflow-x: visible !important;
    overflow-y: visible !important;
} */

.word-break-all {
    word-break: break-all;
}

.upload-input-box {
    border: 1px dashed #C5C5C5;
    position: relative;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 8px;
}

.upload-input-label {
    display: flex;
}

.upload-input-label img {
    margin: 0 8px 0 0;
}

.upload-input-box input {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.banklist-selection-wrapper .external-bank-list {
    padding: 13px 20px 0 20px;
}

.banklist-selection-wrapper .bank-logo {
    width: 25px;
}

.banklist-selection-wrapper .external-bank-list .default-checkbox {
    margin: 0;
    padding: 1px 0px;
}

.adv-search-datepicker-heading {
    position: absolute;
    font-weight: 600;
    top: -20px;
    left: 0px;
    font-size: 12px;
}

.border-top-E6E8F0 {
    border-top: 1px solid #e8e8e8;
}

.checkout-account-details .label-account {
    min-width: 130px;
}

.checkout-arrows {
    margin: -2px 0 0 0;
}

.checkout-header {
    padding: 10px 20px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hide-error-message {
    display: none;
}

.creditscoreapproveicon img {
    margin-top: -2px;
    margin-right: 3px;
}

.lender-submit-payment-info {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
}

/* dashbaord page scrollbar changes */
.dealer-notification-widget .dealer-notifcations .scroll-content {
    max-height: calc(var(--dashBoardPage-height) - 278px) !important;
    min-height: calc(var(--dashBoardPage-height) - 278px) !important;
}

.upcoming-events-section {
    max-height: calc(var(--dashBoardPage-height) - calc(var(--upComingEvents-height) + 30px)) !important;
    min-height: calc(var(--dashBoardPage-height) - calc(var(--upComingEvents-height) + 30px)) !important;
}

.multi-dealers-wrapper .cust-multi-select-box .select-box-with-icon,
.multi-dealers-wrapper .cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.multi-dealers-wrapper .cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 36px !important;
    height: auto;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    z-index: 3 !important;
}

/******** Plaid reports styles *************/

.plaid-report table th span.week-list {
    max-width: 80px;
    min-width: 80px;
    justify-content: flex-end;
}

.plaid-report table th span.week-list span {
    max-width: 55px;
}

.plaid-report table span.data-list {
    max-width: 80px;
    min-width: 80px;
    display: inline-block;
    white-space: break-spaces;
    line-height: 21px;
    direction: ltr;
}

.plaid-report table .row-item-flex {
    max-width: 1025px;
    margin: 0 auto;
}

.menu-open .plaid-report table .row-item-flex {
    max-width: 820px;
}

.plaid-report .bank-details-in-table .counts-list {
    min-width: 180px !important;
}

.plaid-report .data-row-item-flex .data-item {
    margin-bottom: 0;
}

.plaid-report .bank-details-in-table .counts-list,
.plaid-report .data-row-item-flex .data-item {
    min-height: 45px;
}

.plaid-report .data-row-item-flex .data-item:nth-child(n + 2) .data-list,
.plaid-report .bank-details-in-table .counts-list:nth-child(n + 2) {
    border-top: 1px solid #dee2e6;
    padding-top: 5px;
}

.plaid-report .bank-details-in-table .counts-list:nth-child(n + 2) {
    position: relative;
}

.plaid-report .bank-details-in-table .counts-list:nth-child(n + 2)::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 1px;
    background-color: #dee2e6;
    top: -1px;
    right: -15px;
}


.vehicle-history-fr .activity-details-in-print {
    min-width: 150px;
}

.vehicle-history-fr .dealer-details-in-print {
    max-width: 350px;
}

.task-history-fr .last-activity-date p {
    min-width: 150px;
}

.notes-html-parse {
    min-width: 100px;
    overflow: hidden;
    display: -webkit-box;
    max-width: 120px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.notes-html-icon {
    margin: -32px 0 0 0;
    display: inline-block;
}

.notes-html-parse * {
    font-size: 14px !important;
    margin: 0 !important;
    line-height: 18px;
}

/* fonts family styles for the view  notes formatter */
.ql-font-serif {
    font-family: Georgia, Times New Roman, serif !important;
}

.ql-font-monospace {
    font-family: Monaco, Courier New, monospace !important;
}

.dealer-make-other-payments-page-wrapper .payment-amount-in-column {
    min-width: 135px;
    max-width: 135px;
}

.inventory-note-table-filters>.table-filter-actions .action-item {
    display: flex;
    padding-top: 4px;
}

.info-dropdown {
    display: inline-block;
    line-height: 14px;
    margin: -1px 0 0 0;
}

.common-info-icon {
    width: 15px;
}

.data-tooltip .common-info-icon {
    margin: -3px 0 0 0;
}

.adv-search-close {
    flex: 1 1 12px;
    display: inline-block;
    line-height: 18px;
}

/* Bulk Upload for supplier css here */
.bulkupload-title {
    display: flex;
    padding: 10px 0px;
    margin: 0px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
}

.supplier-bulk-upload-popup input {
    max-width: 70px;
}

.supplier-bulk-upload-popup td {
    min-width: 70px;
}

.supplier-bulk-upload-popup .vehicle-details-in-table input {
    max-width: 100%;
    margin-top: 0px;
}

.supplier-bulk-upload-popup .vehicle-upload-document-dropdown button,
.supplier-bulk-upload-popup .vehicle-upload-document-dropdown button:hover,
.supplier-bulk-upload-popup .vehicle-upload-document-dropdown button:focus {
    font-size: 12px;
    min-height: 34px;
    padding: 5px;
}

.supplier-bulk-upload-popup .default-table-actions-dd .dropdown-item {
    padding: 10px;
    font-size: 12px;
}

.supplier-bulk-upload-popup .vehicle-upload-document-dropdown .default-table-actions-dd img {
    height: 18px;
}

.vehicle-upload-document-dropdown>button {
    min-width: 188px;
    justify-content: start;
    text-align: left;
}

.mw-90px {
    min-width: 90px;
    max-width: 90px;
}

.minWidth-125 {
    min-width: 125px !important;
}

.bank-rotate-refresh {
    margin: 0px 0 0 6px;
    width: 13px;
}

.vehicle-registry-report-table .filter-in-table .advanced-filtered-item {
    padding: unset;
    border: unset;
    border-radius: unset;
    display: block;
}

.icon-size-17px {
    width: 17px;
    height: 17px;
}

/* .dealer-notification-widget .dealer-notifcations .scroll-content {
    max-height: 100px; 
    min-height: 100px; 
} */

/* .upcoming-events-section.month_view {
    max-height: 100px;
    min-height: 100px;
} */

/* .upcoming-events-section.month_view.month-with-six-weeks {
    max-height: calc(var(--dashBoardPage-height) - var(--upComingEvents-height)) !important;
    min-height: calc(var(--dashBoardPage-height) - var(--upComingEvents-height)) !important;
} */


/* view application common css start here */
.view-application-popup-fields>div {
    max-width: calc(50% - 7px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 8px);
    flex-grow: 1;
    margin: 0 15px 0 0;
}

.view-application-popup-fields>div:nth-child(even) {
    margin-right: 0;
}

.add-update-location .cust-multi-select-box .select-box-with-icon,
.add-update-location .cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.add-update-location .cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 33px !important;
}

.mw-100 {
    min-width: 100% !important;
    max-width: 100% !important;
}

.compliance-table input {
    padding: 9px !important;
}

.compliance-table.default-table input:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}

.compliance-table.default-table input {
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    border: 1px solid #E0E0E0;
}

.compliance-table input:focus {
    background-color: #fff;
    outline: 2px solid #243B7F !important;
}

.edit-profile-bankselection .page-without-info-widgets .table_scroller {
    max-height: unset !important;
}

.ql-editor a {
    color: #243B7F !important;
}

.funding-details-field-wrap>.default-fieldset {
    padding: 0 12px 12px 12px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.funding-details-field-wrap>.default-fieldset:focus-within {
    border: 1px solid #D1D5DB !important;
}

.valuation-details .funding-details-field-wrap .funding-type-fields-three-col {
    margin-bottom: 15px !important;
}

.valuation-details .funding-details-field-wrap > .default-fieldset {
    margin-bottom: -15px !important;
}

.valuation-details .funding-details-field-wrap > .default-fieldset > .funding-type-fields-three-col:last-child {
    margin-bottom: 0px !important;
}

.funding-details-field-wrap .default-fieldset .funding-details-wrap-legend {
    display: inline-block;
    font-size: 12px;
    width: auto;
    padding: 0 5px;
    font-weight: 600;
    margin: 0 0 4px 0;
}

.funding-details-field-wrap .form-group {
    margin-bottom: 10px !important;
}

.flooring-vehicle-details .funding-details-field-wrap {
    margin-bottom: 15px !important;
}

/* view application common css start here */

.confirm-and-submit-table .bank-acc-pays-in-table .cust-multi-select-box {
    max-width: 65%;
}

.dealer-notification-widget .nav-tabs .nav-link:first-child {
    padding-left: 20px !important;
}

.notification-details-widget .notification-white-modal .tabs-with-table {
    box-shadow: none;
    border-radius: 0;
}



.weekly-performance-table-wrapper .expandtableArrow {
    position: absolute;
    left: 105px;
    top: 7px;
    z-index: 5;
    cursor: pointer;
}

.weeklyPlaidReport-table-wrapper td:has(> div.showBlueLine) {
    border-left: 2px solid #243b7f;
}

.viewloan-transaction-table-wrapper .expand-rows-table .vehicleSideLabels {
    min-width: 81px;
}

.breadcrumb-arrow {
    line-height: 18px;
}

.tooltip-notes-cell {
    white-space: normal;
}

.tooltip-notes-wrap,
.line-increase-accordion .data-tooltip .tooltip-notes-cell {
    max-width: 300px !important;
    word-break: break-word;
}

.line-increase-accordion .data-tooltip .table-responsive {
    max-height: unset;
}

.banklist-selection-wrapper .plaid-success-btn,
.banklist-selection-wrapper .reconnect-plaid-btn {
    font-size: 10px;
    min-width: auto;
    padding: 7px;
    min-height: auto;
}

.holiday-date {
    flex: 1 1 40%;
    max-width: 40%;
}

.holiday-description {
    flex: 1 1 57%;
    max-width: 57%;
}

.s3-files-wrapper .table-filter-actions {
    top: -40px;
}

.scheduler-inner-jsx {
    display: inline-table;
}

.scheduler-inner-jsx td:first-child {
    padding-left: 41px;
}

.transaction-summary-table .default-table .actions-in-table {
    max-width: 110px !important;
    min-width: 110px !important;
    padding-top: 6px;
    padding-bottom: 6px;
}

.summary-header p {
    font-size: 12px;
    padding: 11.5px 0;
}

.summary-header>div {
    padding: 0 20px;
}

.submit-payment-page-table .payment-type,
.payment-naretive {
    width: 200px;
}

.vehicleDetails {
    display: flex;
}

.historical-graph-previous-arrow {
    left: 35px;
}

.menu-open .historical-graph-previous-arrow {
    left: 32px;
}

.historical-graph-next-arrow {
    right: 13px;
}

.historical-graph-previous-arrow,
.historical-graph-next-arrow {
    bottom: 19px;
    z-index: 85;
}

.history-graph-section {
    padding: 20px 20px 0px 8px !important;
    margin: 0 0 30px 0;
    box-shadow: none;
}

.approval-pending-text {
    color: #A67905 !important;
    font-size: 10px !important;
}

/* View Application Starts */
.decisioningTab {
    padding: 30px 20px;
}

.card-body-decisioning {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.card-body-decisioning>div {
    min-width: 31%;
    max-width: 33%;
    flex: 1 1 31%;
    margin: 0 15px 10px 0;
}

.card-body-decisioning>div:nth-child(3n) {
    margin-right: 0;
}

.card-body-decisioning>div:last-child,
.inner-card-body-decisioning .card-body-decisioning>div:last-child {
    /* margin: 0; */
}

.inner-card-body-decisioning h6 {
    text-align: center;
}

.card-body-decisioning .inner-card-body-decisioning {
    /* border: 1px solid #E5E7EB; */
    border-radius: 8px;
    min-width: 48%;
    max-width: 48%;
    flex: 1 1 48%;
    margin: 0;
    /* overflow: hidden; */
}

.inner-card-body-decisioning .card-body-decisioning>div {
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
}

.inner-card-body-decisioning .card-body-decisioning>div.fixed-width-fields-div {
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
    margin: 0 auto;
}

.inner-card-parent.card-body-decisioning {
    padding: 30px 20px;
}

.card-body-decisioning .card-body-decisioning {
    border: 1px solid #E5E7EB;
    border-top: 0;
    /* overflow: hidden; */
    border-radius: 0 0 8px 8px;
}

.inner-card-body-decisioning .view-form-label {
    min-width: 70%;
}

.inner-card-body-decisioning .card-header-style {
    border-radius: 8px 8px 0 0;
}

.contract-details-left .card-body-decisioning>div {
    margin-bottom: 0 !important;
    padding: 5px 10px;
    margin-left: -10px;
}

.interest-rate-details .card-body-decisioning {
    flex-grow: 1;
}

.view-form-label {
    font-size: 14px;
    font-weight: 700;
    width: 49%;
    flex: 1 1;
}

.contract-details-left .view-form-label {
    min-width: auto;
}

.card-styles-decisioning {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    margin: 0 0 30px 0;
}

.card-styles-decisioning.contract-details {
    margin: 0 0 30px 0
}

.fixed-width-fields-div>div {
    margin-bottom: 10px;
}

.fixed-width-fields-div>div:last-child {
    margin-bottom: 0;
}

.total-row-border {
    border-top: 1px solid black;
    padding-top: 8px;
}

/* View Application Ends */
.tags-dropdown-loading {
    height: 100px;
    width: 215px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.tags-dropdown-loading img.loader-gif-icon {
    width: 40px;
}

.tags-dropdown-loading .app-loader {
    border-radius: 10px;
}

.tags-dropdown-loading td {
    background-color: white !important;
}

.reconsile-reports-tabs .advance-search-filters-deafult-data-type {
    padding-bottom: 0 !important;
}

.reconsile-table-wrapper .table-filter-actions {
    z-index: 4 !important;
}

.audits-asset-info-popup .default-content-wrapper {
    padding: 0;
}

.audits-asset-info-popup .fullVehicleDetailsPage .tabs-with-table {
    box-shadow: none;
}

.portfolioMetricsFilter.car-floored-datepicker {
    align-items: center;
    padding: 8px 10px;
}

.audit-history-widget .get-btn button {
    background-color: #243B7F !important;
    min-width: 80px !important;
    color: white !important;
    margin: 3px 0 0 0;
}

.audit-history-widget .default-table-actions-dd .dropdown-menu {
    max-height: unset;
    overflow-y: visible;
}

.audit-history-widget .default-table-actions-dd .dropdown-toggle img {
    margin: -4px 0 0 0;
}

.audit-history-widget .react-datepicker-wrapper .react-datepicker__close-icon {
    right: 43px;
}

.audit-history-widget .default-date-picker .react-datepicker__navigation--previous,
.audit-history-widget .default-date-picker .react-datepicker__navigation--next {
    border-bottom: 2px solid #111 !important;
    border-left: 2px solid #111 !important;
}

.report-vin-bulk .upload-btn {
    height: 40px;
    width: 100px;
}

.report-vin-bulk .upload-file-name {
    margin-top: -6px;
}

.report-vin-bulk .file-upload-input-group {
    /* margin: 0 40px; */
    border-radius: 5px;
    overflow: hidden;
    min-width: 310px;
}

.bill-of-sale-report .myinventory-table-header {
    padding: 20px;
}

.bill-of-sale-report .upload-wrapper {
    min-width: 310px;
    margin: -10px 0 0 0;
}

.bill-of-sale-report .table-filter-actions {
    top: -45px;
}

.border-top-E6E8F0 {
    border-top: 1px solid #E6E8F0;
}

/* credit line new page css here */
.creditLineNameInfo {
    border-bottom: 1px solid #E6E8F0;
    padding: 10px 12px;
    align-items: center;
    margin: 0;
    align-items: center;
}

.creditLineNameInfo .widget-title {
    align-self: center;
}

.creditline-content-wrapper .creditLineNameInfo .widget-title {
    font-size: 18px;
}

.creditline-content-wrapper .infoWidget p {
    font-size: 16px;
}

.multi-credit-sidenav {
    width: 22px;
}

.creditLineShortDataInfo {
    padding: 20px 12px;
    align-items: center;
    margin: 0;
}

.creditLineShortDataInfo .info-widget-row .col {
    margin-bottom: 10px;
}

.creditLineProgressInfo .infoWidget {
    box-shadow: none;
    padding: 0px;
}

.creditLineProgressInfo .info-icon {
    display: none !important;
}

.creditLineProgressBar {
    margin-bottom: 12px;
    border-radius: 30px;
    height: 20px;
}

.creditLineProgressBar .bg-success {
    background-color: #0E8074 !important;
}

.creditLineProgressBar .bg-warning {
    background-color: #A67905 !important;
}

.creditLineProgressBar .bg-danger {
    background-color: #A10F0F !important;
}

.creditLineDataSection {
    padding: 20px 10px;
    border-top: 1px solid #E6E8F0;
    margin: 0;
}

.creditLineDataSection>div {
    padding: 0 10px;
}

.creditLineDataGrid {
    border: 1px solid #E6E8F0;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    box-shadow: 0px 1px 4px 0px #64748B1F;

}

.creditLineDataGrid .titleSection {
    background-color: #fafafa;
    border-bottom: 1px solid #E6E8F0;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.creditLineDataGrid .titleWithIcon {
    display: flex;
    align-items: center;
}

.creditLineDataGrid .dataSection ul {
    padding: 0px;
    margin: 0px;
}

.creditLineDataGrid .dataSection li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 5px 10px;
    border-bottom: 1px solid #E6E8F0;
    color: #333;
}

.creditLineDataGrid .dataSection .totalRecords {
    padding: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.creditLineDataGrid .dataSection .totalRecords.success {
    background-color: #DFFFE8;
    color: #0E8074;
}

.creditLineDataGrid .dataSection .totalRecords.error {
    background-color: #FFE5E5;
    color: #A10F0F;
}

.creditLineDataGrid .dataTotalCount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 8px;
}

.footer-info-widget img {
    width: 25px;
}

.creditLineDataGrid .titleWithIcon img {
    /* width: 18px; */
    height: 20px;
}

.creditLineDataGrid .titleSection .default-btn {
    font-size: 12px;
    min-height: 32px;
}

.no-bg-info-widget .infoWidget {
    background-color: transparent;
    box-shadow: none;
}

.overlappedProgressData {
    /* margin-top: -32px; */
    text-align: center;
}

.overlappedProgressData h6 {
    color: #fff;
}

.menu-open .creditLineProgressInfo {
    max-width: 45%;
}


/*  no icon info widgets */
.noIconTopInfoWidgets {
    margin: 0px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 23px;
}

.noIconTopInfoWidgets.info-widget-row .col {
    padding: 0px !important;
    margin-bottom: 0px;
}

.noIconTopInfoWidgets .infoWidget {
    box-shadow: none;
    border: 0px;
    border-radius: 0px;
    padding-right: 0px;
    background-color: transparent;
}

.noIconTopInfoWidgets .infoWidget .flex-grow-1 {
    padding-right: 18px;
    border-right: 1px solid #ebebeb;
    display: flex;
    flex-direction: column-reverse;
}

.noIconTopInfoWidgets .infoWidget .flex-grow-1 small {
    margin-bottom: 3px;
}

.noIconTopInfoWidgets .infoWidget .info-icon {
    display: none !important;
}

.cs-tooltip {
    vertical-align: middle;
}

.increase-amount-wrapper .active-accordion-arrow img {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
}

.adv-search-datepicker-heading {
    position: absolute;
    font-weight: 500;
    top: -20px;
    left: 0px;
    font-size: 12px;
}

.text-transform-unset {
    text-transform: none !important;
    text-transform: initial !important;

    &::first-letter {
        text-transform: none;
        text-transform: initial;
    }
}

.compliance-status .compliance-status-icon {
    margin: 4px 0 0 0;
}

.compliance-status svg.compliance-status-icon {
    margin: 1px 0 0 0 !important;
}

.compliance-status .label-status {
    min-width: 75px;
}

.compliance-status .compliance-comments {
    margin: 1px 0 0 0px !important;
}

.roboto-text {
    font-family: 'Roboto', sans-serif !important;
}

.create-asset-inv-popup .white-component-modal {
    box-shadow: none !important;
}

.create-asset-inv-popup .table-filter-actions {
    z-index: 99;
    right: -10px;
    top: -50px;
}

.create-asset-inv-popup .table-in-popup .table-responsive {
    max-height: 52vh;
}

.create-asset-inv-popup .tabs-with-table {
    box-shadow: unset;
    border-radius: unset;
}

.create-asset-inv-popup .advancedSearchFilters {
    padding: 0 0 30px 0 !important;
}

.create-asset-inv-popup .create-asset-popup-body {
    margin-top: 20px !important;
}

.create-asset-inv-popup .advancedSearchFilters>div {
    padding: 0 !important;
    max-width: 30.5% !important;
}

.create-asset-inv-popup .advancedSearchFilters .advance-filters-actions {
    padding: 0 !important;
    max-width: 100% !important;
}

.singleLineTitleValues{
    display: flex;
    justify-content: space-between;
}
.mr-50{
    margin-right: 50px;
}

.dealer-payment-history .expand-icon {
    position: absolute;
    left: -27px;
    top: 0;
}
.backtoDashboardLink {
    padding: 7px 24px 0px;
    margin-bottom: -10px;
    text-align: right;
}
.bank-transactions-popup .table-filter-actions {
    right: 17px;
    bottom: -5px;
    align-items: flex-end;
}

.fedex-popup.default-popup .table_scroller {
    max-height: 61vh !important;
}


.credit-outstanding-graph .white-component-modal>div {
    height: 350px;
    margin-bottom: 30px;
    padding-bottom: 31px !important;
}

.credit-outstanding-graph .prev-arrow {
    position: absolute;
    left: 35px;
    bottom: 39px;
}

.credit-outstanding-graph .next-arrow {
    right: 35px;
    position: absolute;
    bottom: 39px;
}

.credit-outstanding-graph .no-data-chart {
    margin-bottom: 0px !important;
}

.credit-outstanding-graph .apexcharts-svg,
.credit-outstanding-graph .apexcharts-svg>foreignObject {
    height: 365px;
    margin-left: -5px;
}

.payment-tab-top-section.fullView {
    max-height: 0;
    margin: 0px;
    display: none;
}

/* Keyframes for animation */
@keyframes overlap-animation {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-450px);
    }
}

/*  Responsive styling start here  */

@media(max-width: 1919px) {
    .plaid-report table .row-item-flex {
        max-width: 900px !important;
    }

    .menu-open .plaid-report table .row-item-flex {
        max-width: 500px !important;
    }

    .creditLineProgressBar {
        height: 17px;
    }

    .overlappedProgressData h6 {
        font-size: 14px;
    }

    .creditline-content-wrapper .creditLineNameInfo .widget-title {
        font-size: 18px;
    }
}

@media (min-width: 1801px) {

    .primary-level-tabs {
        margin-bottom: 28px;
    }
}

@media(max-width: 1700px) {

    .menu-open .vehicle-reports-wrapper .nav-link {
        width: 11%;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .nav-tabs .nav-link .tabName {
        display: block;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .nav-tabs {
        align-items: flex-end;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .table-filter-actions {
        top: 26px;
    }

    .menu-open .auctionAccessTab .nav-tabs .nav-link {
        margin-right: 12px;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .nav-tabs .nav-link {
        margin-right: 23px;
    }

}

@media((min-width: 1600px) and (max-width: 1700px)) {


    .menu-open .auctionAccessTab .auction-access-show-pill {
        /* left: 570px; */
        left: 725px;
    }
}

@media(max-width: 1700px) {}

@media (min-width: 1600px) {

    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 355px) !important;
        min-height: calc(var(--dashBoardPage-height) - 355px) !important;
    }

    .auction-notification-widget.dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 315px) !important;
        min-height: calc(var(--dashBoardPage-height) - 315px) !important;
    }

    .supplierDashboardChart {
        max-height: calc(var(--dashBoardPage-height) - 448px) !important;
        min-height: calc(var(--dashBoardPage-height) - 448px) !important;
    }
}

@media (min-width:1200px) {

    /* My dealers dealer table alignment changes */
    .my-dealers-wrapper .dealer-details-in-table {
        width: 25.6vw;
    }

    .my-dealers-wrapper .bos-dealer-details-in-table {
        width: 26vw;
    }

    .my-dealers-wrapper .dealer-contact-information-in-table {
        width: 20.6vw;
    }

    .my-dealers-wrapper .bos-dealer-contact-information-in-table {
        width: 26vw;
    }

    .my-dealers-wrapper td .utlization-wrapper,
    .archive-tab-table .terminated-dealers-wrapper td .utlization-wrapper {
        width: 80%;
    }

    .my-dealers-wrapper .utlization-wrapper {
        width: 23.6vw;
        min-width: auto;
        max-width: 100%;
    }

    .my-dealers-wrapper .bos-utlization-wrapper {
        width: 27vw;
        min-width: auto;
        max-width: 100%;
    }

    .my-dealers-wrapper .bank-details-in-table {
        width: 15.6vw;
        min-width: auto;
        max-width: 100%;
    }

    .my-dealers-wrapper .vehicles-floored-in-table {
        width: 14.6vw;
    }

    .my-dealers-wrapper .bos-vehicles-floored-in-table {
        width: 21vw;
    }


    .archive-tab-table .terminated-dealers-wrapper .dealer-details-in-table,
    .archive-tab-table .terminated-dealers-wrapper .dealer-contact-information-in-table,
    .archive-tab-table .terminated-dealers-wrapper .utlization-wrapper {
        width: 28vw;
    }

    .archive-tab-table .terminated-dealers-wrapper .vehicles-floored-in-table {
        width: 16vw;
    }

    .current-month-subscription-table .total-in-table input,
    .current-month-subscription-table .discounts-in-table input,
    .current-month-subscription-table .fees-in-table input,
    .current-month-subscription-table .titles-in-table input,
    .current-month-subscription-table .other-in-table input {
        min-width: 100px;
    }

    /* My dealers supplier table alignment changes */
    .supplier-page-wrapper .supplier-details-in-table {
        width: 31vw;
    }

    .supplier-page-wrapper .supplier-contact-information-in-table {
        width: 26vw;
    }

    .supplier-page-wrapper .vehicle-pendingpayoff-in-table {
        width: 22vw;
    }

    .supplier-page-wrapper .vehicle-pendingpayoff-in-table p {
        width: 80%;
    }

    .supplier-page-wrapper .total-pendingfunding-in-table {
        width: 19vw;
    }

    /* funding request page wrapper table alignmnet changes */
    .funding-request-page-wrapper .dealer-details-in-table {
        width: 27.2vw;
    }

    .funding-request-page-wrapper .vehicle-details-in-table {
        width: 27.2vw;
    }

    .funding-request-page-wrapper .checklist-in-table {
        width: 16.2vw;
    }

    .funding-request-page-wrapper .requestedamount-in-table {
        width: 16.2vw;
    }

    .funding-request-page-wrapper .loanstatus-in-table {
        width: 14.2vw;
    }

    .funding-request-page-wrapper .floored-task-pending-wrapper .dealer-details-in-table {
        width: 22vw;
    }

    .funding-request-page-wrapper .floored-task-pending-wrapper .vehicle-details-in-table {
        width: 22vw;
    }

    .funding-request-page-wrapper .floored-task-pending-wrapper .createddate-in-table {
        width: 12vw;
    }

    .funding-request-page-wrapper .floored-task-pending-wrapper .createddate-in-table .dateandtime-in-table {
        width: 10.5vw;
    }

    .funding-request-page-wrapper .floored-task-pending-wrapper .taskname-in-table {
        width: 22vw;
    }

    .funding-request-page-wrapper .description-in-table {
        width: 22vw;
    }

    .funding-request-popup-wrapper .dealer-details-in-table {
        width: 24.2vw;
    }

    .funding-request-popup-wrapper .vehicle-details-in-table {
        width: 24.2vw;
    }

    .funding-request-popup-wrapper .lastactivity-in-table {
        width: 19.2vw;
    }

    .funding-request-popup-wrapper .taskname-in-table {
        width: 15vw;
    }

    .funding-request-popup-wrapper .description-in-table {
        width: 22vw;
    }

    .funding-request-popup-wrapper .requestedamount-in-table {
        width: 13vw;
    }

    .funding-request-popup-wrapper .loanstatus-in-table {
        width: 15.2vw;
    }

    .funding-request-popup-wrapper .taskname-in-table {
        width: 17.2vw;
    }

    /* lender titles page wrapper table alignmnet changes */
    .titles-page-wrapper .dealer-details-in-table {
        width: 20vw;
    }

    .titles-page-wrapper .vehicle-details-in-table {
        width: 20vw;
    }

    .titles-page-wrapper .funding-details-in-table {
        width: 16.5vw;
    }

    .titles-page-wrapper .flooreddays-in-table {
        width: 11.5vw;
    }

    .titles-page-wrapper .titlestatus-in-table {
        width: 11.5vw;
    }

    .titles-page-wrapper .titlenotes-in-table {
        width: 10.5vw;
    }

    .titles-page-wrapper .payments-in-table {
        width: 10vw;
    }

    /* My dealers payments table alignment changes */
    .lender-payments-page-wrapper .dealer-details-in-table {
        width: 22vw;
    }

    .lender-payments-page-wrapper .vehicle-details-in-table {
        width: 20vw;
    }

    .lender-payments-page-wrapper .createddate-in-table {
        width: 14.5vw;
    }

    /* .lender-payments-page-wrapper .createddate-in-table small {
        width: 80%;
    } */

    .lender-payments-page-wrapper .taskname-in-table {
        width: 19vw;
    }

    .lender-payments-page-wrapper .description-in-table {
        width: 25vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .dealer-details-in-table {
        width: 21vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .dealer-details-in-table {
        width: 23vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .vehicle-details-in-table {
        width: 20vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .vehicle-details-in-table {
        width: 22vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .description-in-table {
        width: 15vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .description-in-table {
        width: 17vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .duedate-in-table {
        width: 12.5vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .duedate-in-table {
        width: 14.5vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .billingamount-in-table {
        width: 11vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .billingamount-in-table {
        width: 13vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .titlenotes-in-table {
        width: 9.5vw;
    }

    .lender-payments-page-wrapper .upcoming-due-auditor-wrapper .titlenotes-in-table {
        width: 10.5vw;
    }

    .lender-payments-page-wrapper .upcoming-payments-due-page-wrapper .paymentactions-in-table {
        width: 10vw;
    }

    .lender-payment-history-popup .dealer-details-in-table {
        width: 22vw;
    }

    .lender-payment-history-popup .vehicle-details-in-table {
        width: 20vw;
    }

    .lender-payment-history-popup .lastactivity-in-table {
        width: 14vw;
    }

    .lender-payment-history-popup .taskname-in-table {
        width: 18vw;
    }

    .lender-payment-history-popup .description-in-table {
        width: 24vw;
    }

    /* lender payments in progress tab */
    .lender-payments-inprogress-wrapper .dealer-details-in-table {
        width: 25.6vw;
    }

    .lender-payments-inprogress-wrapper .vehicle-details-in-table {
        width: 23.6vw;
    }

    .lender-payments-inprogress-wrapper .description-in-table {
        width: 19.6vw;
    }

    .lender-payments-inprogress-wrapper .scheduled-date-in-table {
        width: 16.6vw;
    }

    .lender-payments-inprogress-wrapper .payment-amount-in-table {
        width: 14.6vw;
    }

    /* lender inventory in progress tab */
    .recovery-tracking-popup .recovery-table .vehicle-in-table {
        width: 25vw;
    }

    .recovery-tracking-popup .recovery-table .recoveryAmount-in-table {
        width: 17vw;
    }

    .recovery-tracking-popup .recovery-table .auction-in-table {
        width: 18vw;
    }

    .recovery-tracking-popup .recovery-table .type-in-table,
    .recovery-tracking-popup .recovery-table .netProceeds-in-table,
    .recovery-tracking-popup .recovery-table .date-in-table,
    .recovery-tracking-popup .recovery-table .gross-in-table {
        width: 15vw;
    }

    /* Lender --> Servicing History table */
    .servicing-history-popup .dealer-details-in-table {
        width: 22vw;
    }

    .servicing-history-popup .vehicle-details-in-table {
        width: 20vw;
    }

    .servicing-history-popup .lastActivity-date-in-table {
        width: 18vw;
    }

    .servicing-history-popup .approved-details-in-table {
        width: 20vw;
    }

    .servicing-history-popup .task-name-in-table {
        width: 20vw;
    }

    .servicing-history-popup .description-in-table {
        width: 20vw;
    }

    /* Lender -- Risk page table */
    .riskdashboard-dealerlist-table .dealer-details-in-table {
        width: 15vw;
        min-width: 15vw;
        max-width: 15vw;
    }

    .risk-alerts-table .dealer-details-in-table {
        width: 30vw;
    }

    .risk-alerts-table .risk-alerts-in-table {
        width: 28vw;
    }

    .risk-alerts-table .date-in-table {
        width: 24vw;
    }

    .risk-alerts-table .notes-in-table {
        width: 19vw;
    }

    .read-only-access.risk-alerts-table .dealer-details-in-table {
        width: 27vw;
    }

    .read-only-access.risk-alerts-table .risk-alerts-in-table {
        width: 30vw;
    }

    .read-only-access.risk-alerts-table .date-in-table {
        width: 18vw;
    }

    .read-only-access.risk-alerts-table .notes-in-table {
        width: 33vw;
    }

    /* Dealer Alerts Table */
    .alerts-tasks-table-wrapper .vehicle-details-in-table {
        width: 25vw;
    }

    .alerts-tasks-table-wrapper .dealer-name-in-table {
        width: 13vw;
    }

    .alerts-tasks-table-wrapper .sent-date-in-table {
        width: 14vw;
    }

    .alerts-tasks-table-wrapper .sent-date-in-table p {
        max-width: 80%;
    }

    .alerts-tasks-table-wrapper .status-in-table {
        width: 10vw;
    }

    .alerts-tasks-table-wrapper .description-in-table {
        width: 13vw;
    }

    .alerts-tasks-table-wrapper .task-notification-file-upload {
        width: 10.5vw;
    }

    .alerts-tasks-table-wrapper .task-description-in-table {
        width: 33vw;
    }

    /*  Dealer dashboard my inventory table alignment changes  */
    .dealer-dashboard-myinventory-page-wrapper .vehicle-details-in-table {
        width: 25vw;
    }

    .menu-open .dealer-dashboard-myinventory-page-wrapper .vehicle-details-in-table {
        width: 21vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .principalbalance-in-table {
        width: 19vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .interestbalance-in-table {
        width: 14vw;
    }

    .menu-open .dealer-dashboard-myinventory-page-wrapper .principalbalance-in-table {
        width: 15vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .flooreddays-in-table {
        width: 12vw;
    }

    .menu-open .dealer-dashboard-myinventory-page-wrapper .flooreddays-in-table {
        width: 9vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .titlestatus-in-table {
        width: 16vw;
    }

    .menu-open .dealer-dashboard-myinventory-page-wrapper .titlestatus-in-table {
        width: 9vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .nextpaymentdue-in-table {
        width: 14vw;
    }

    .menu-open .dealer-dashboard-myinventory-page-wrapper .nextpaymentdue-in-table {
        width: 11vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .bos-vehicle-details-in-table {
        width: 46vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .bos-principalbalance-in-table {
        width: 34vw;
    }

    .dealer-dashboard-myinventory-page-wrapper .bos-flooreddays-in-table {
        width: 20vw;
    }

    /*  Dealer dashboard make other payments table alignment changes  */
    .dealer-make-other-payments-page-wrapper .vehicle-details-in-table {
        width: 20vw;
    }

    .dealer-make-other-payments-page-wrapper .rolled-vehicle-details-in-table {
        width: 28vw;
    }

    .dealer-make-other-payments-page-wrapper .principalbalance-in-table {
        width: 15vw;
    }

    .dealer-make-other-payments-page-wrapper .interestbalance-in-table {
        width: 11vw;
    }

    .dealer-make-other-payments-page-wrapper .totaldue-in-table {
        width: 8vw;
    }

    .dealer-make-other-payments-page-wrapper .nextpaymentdue-in-table {
        width: 12vw;
    }

    .dealer-make-other-payments-page-wrapper .paycurtailment-in-table {
        width: 10vw;
    }

    .dealer-make-other-payments-page-wrapper .payinfull-in-table {
        width: 10vw;
    }
    
    .dealer-make-other-payments-page-wrapper .payment-amt-col {
        width: 10vw;
    }

    .dealer-make-other-payments-page-wrapper .actions-in-table {
        width: 6.5vw;
    }

    /* Dealer dashboard make other payments table alignment changes */
    .dealer-make-other-payments-page-wrapper .bos-vehicle-details-in-table {
        width: 38vw;
    }

    .dealer-make-other-payments-page-wrapper .supplier-payments-in-table {
        width: 22vw;
    }

    .dealer-make-other-payments-page-wrapper .bos-principalbalance-in-table {
        width: 26vw;
    }

    .dealer-make-other-payments-page-wrapper .bos-payinfull-in-table {
        width: 20vw;
    }

    .dealer-make-other-payments-page-wrapper .lender-payments-inprogress-table .asset-in-table,
    .dealer-payment-history .viewloan-transaction-table-wrapper .lender-payments-inprogress-table .asset-in-table {
        width: 48vw !important;
    }
    
    .dealer-make-other-payments-page-wrapper .lender-payments-inprogress-table .payment-desc-in-table,
    .dealer-payment-history .viewloan-transaction-table-wrapper .lender-payments-inprogress-table .payment-desc-in-table {
        width: 31vw !important;
    }
    
    .dealer-make-other-payments-page-wrapper .lender-payments-inprogress-table .amount-in-table,
    .dealer-payment-history .viewloan-transaction-table-wrapper .lender-payments-inprogress-table .amount-in-table {
        width: 21vw !important;
        max-width: unset !important;
        min-width: auto !important;
        margin-left: unset !important;
        margin-right: unset !important;
        display: table-cell !important;
    }

    .dealer-payments-inprogress-table .schedule-date-in-table {
        width: 19vw;
    }

    .dealer-payments-inprogress-table .payment-amount-in-table {
        width: 16vw;
    }

    .dealer-make-other-payments-page-wrapper .bos-paymentAmount-in-table {
        width: 16vw;
    }

    .dealer-make-other-payments-page-wrapper .bos-paymentAmount-in-table>div {
        min-width: auto !important;
        max-width: unset !important;
    }

    /*  Dealer dashboard my inventory table alignment changes  */
    .dealer-payments-inprogress-table .vehicle-details-in-table {
        width: 34vw;
    }

    .dealer-payments-inprogress-table .description-in-table {
        width: 24vw;
    }

    .dealer-payments-inprogress-table .schedule-date-in-table {
        width: 23vw;
    }

    .dealer-payments-inprogress-table .payment-amount-in-table {
        width: 20vw !important;
    }

    /*  Dealer portal history page table alignment changes  */
    .subrows-table th:nth-child(2) {
        padding-left: 0;
    }

    .dealer-payment-history .vehicle-details-in-table {
        width: 30.5vw;
    }

    .dealer-payment-history .description-in-table {
        width: 25.5vw;
    }

    .dealer-payment-history .paymentdate-in-table {
        width: 23.5vw;
    }

    .hide-partial.rolled-vehicle-details-in-table {
        width: 28vw;
    }
    .hide-partial.principalbalance-in-table {
        width: 18vw;
    }
    .hide-partial.interestbalance-in-table {
        width: 14vw;
    }
    .hide-partial.totaldue-in-table {
        width: 12vw;
    }
    .hide-partial.payinfull-in-table {
        width: 14vw;
    }
    .hide-partial.paymentAmount-in-table {
        width: 14vw;
    }


    .dealer-payment-history .paymentamount-in-table {
        width: 19vw !important;
    }

    .dealer-payment-history .paymentdetails-in-table {
        width: 22vw !important;
    }

    /* Dealer portal Inventory history table alignment changes */
    .dealer-inventory-history .vehicle-details-in-table {
        width: 32vw;
    }

    .dealer-inventory-history .floordate-in-table,
    .dealer-inventory-history .payoffdate-in-table {
        width: 18vw;
    }

    .dealer-inventory-history .numberofdaysfloored-in-table {
        width: 22vw;
    }

    .dealer-inventory-history .totalcost-in-table {
        width: 14vw;
    }

    /* Lender portal Inventory table alignment chnages */
    .lender-inventory-wrapper .vehicle-details-in-table {
        width: 25.5vw;
    }

    .lender-inventory-wrapper .loanamount-in-table {
        width: 10.5vw;
    }

    .lender-inventory-wrapper .loantype-in-table {
        width: 12.5vw;
    }

    .lender-inventory-wrapper .loanstatus-in-table {
        width: 16.5vw;
    }

    .lender-inventory-wrapper .auditstatus-in-table {
        width: 11.5vw;
    }

    .lender-inventory-wrapper .verificationstatus-in-table {
        width: 7.5vw;
    }

    .lender-inventory-wrapper .titlestatus-in-table {
        width: 10.5vw;
    }

    .lender-inventory-wrapper .ddistatus-in-table {
        width: 9.5vw;
    }

    .lender-inventory-wrapper .actions-in-table {
        width: 5vw;
    }

    .lender-inventory-wrapper .bos-vehicle-details-in-table {
        width: 25vw;
    }

    .lender-inventory-wrapper .bos-loanamount-in-table {
        width: 15vw;
    }

    .lender-inventory-wrapper .bos-loantype-in-table {
        width: 15vw;
    }

    .lender-inventory-wrapper .bos-loanstatus-in-table {
        width: 15vw;
    }

    .lender-inventory-wrapper .bos-auditstatus-in-table {
        width: 15vw;
    }

    .lender-inventory-wrapper .bos-verificationstatus-in-table {
        width: 10vw;
    }

    /* Servicing page tasklist table alignment changes */
    .servicing-tasklist-wrapper .dealer-details-in-table {
        width: 22.5vw;
    }

    .servicing-tasklist-wrapper .vehicle-details-in-table {
        width: 18.5vw;
    }

    .servicing-tasklist-wrapper .taskdata-in-table {
        width: 14.5vw;
    }

    .servicing-tasklist-wrapper .task-in-table {
        width: 20.5vw;
    }

    .servicing-tasklist-wrapper .taskdescription-in-table {
        width: 24.5vw;
    }

    /* Servicing page: PayOff Scheduled table alignment changes */
    .servicing-payoff-scheduled-wrapper .dealer-details-in-table {
        width: 22vw;
    }

    .servicing-payoff-scheduled-wrapper .vehicle-details-in-table {
        width: 19vw;
    }

    .bos-servicing-payoff-scheduled-wrapper .dealer-details-in-table,
    .bos-servicing-payoff-scheduled-wrapper .vehicle-details-in-table {
        width: 26vw;
    }

    .servicing-payoff-scheduled-wrapper .submitted-date-in-table {
        width: 15vw;
    }

    .bos-servicing-payoff-scheduled-wrapper .submitted-date-in-table {
        width: 20vw;
    }

    .servicing-payoff-scheduled-wrapper .scheduled-date-in-table {
        width: 15vw;
    }

    .bos-servicing-payoff-scheduled-wrapper .scheduled-date-in-table {
        width: 20vw;
    }

    .servicing-payoff-scheduled-wrapper .title-status-in-table {
        width: 18vw;
    }

    .servicing-payoff-scheduled-wrapper .notes-in-table {
        width: 11vw;
    }

    .bos-servicing-payoff-scheduled-wrapper .notes-in-table {
        width: 11vw;
    }

    /* Servicing page: Release Payments table alignment changes */
    .release-payments-wrapper .dealer-details-in-table,
    .release-payments-wrapper .vehicle-details-in-table {
        width: 20vw;
    }

    .bos-release-payments-wrapper .dealer-details-in-table,
    .bos-release-payments-wrapper .vehicle-details-in-table {
        width: 23.5vw;
    }


    .release-payments-wrapper .title-description-in-table {
        width: 20vw;
    }

    .release-payments-wrapper .supplier-details-in-table {
        width: 15vw;
    }

    .bos-release-payments-wrapper .supplier-details-in-table {
        width: 18.5vw;
    }

    .release-payments-wrapper .received-date-in-table {
        width: 15vw;
    }

    .bos-release-payments-wrapper .received-date-in-table {
        width: 18.5vw;
    }

    .release-payments-wrapper .approved-amount-in-table {
        width: 13vw;
    }

    .bos-release-payments-wrapper .approved-amount-in-table {
        width: 16.5vw;
    }

    /* Servicing page: Release Payments table alignment changes */
    .vaulted-titles-wrapper .dealer-details-in-table,
    .vaulted-titles-wrapper .vehicle-details-in-table,
    .vaulted-titles-wrapper .supplier-details-in-table {
        width: 28vw;
    }

    .vaulted-titles-wrapper .purchase-price-in-table {
        width: 20vw;
    }

    /* Audits Notes Tables */
    .virtual-auditor-notes .shared-note-table-wrapper th:first-child,
    .virtual-auditor-notes .shared-note-table-wrapper td:first-child {
        width: 60vw;
    }

    .virtual-auditor-notes .shared-note-table-wrapper th:nth-child(2),
    .virtual-auditor-notes .shared-note-table-wrapper td:nth-child(2) {
        width: 30vw;
    }

    .virtual-auditor-notes .shared-note-table-wrapper th:last-child.date-in-table,
    .virtual-auditor-notes .shared-note-table-wrapper td:last-child.date-in-table {
        width: 20vw;
        max-width: unset;
        min-width: unset;
        margin-left: unset;
        margin-right: unset;
        display: table-cell;
    }

    .inperson-auditor-notes .shared-note-table-wrapper .status-in-table {
        width: 10vw;
    }


    /*  Pipeline onboarding page table alignment changes  */
    .onboarding-tab-table .dealer-details-in-table {
        width: 18vw;
    }

    .onboarding-tab-table .applicationsubmiteddate-in-table {
        width: 19vw;
    }

    .onboarding-tab-table .requestedamount-in-table {
        width: 15vw;
    }

    .onboarding-tab-table .creditscore-in-table {
        width: 15vw;
    }

    .onboarding-tab-table .rsm-in-table {
        width: 16vw;
    }

    .onboarding-tab-table .rsm-in-table .cust-multi-select-box.default-fieldset {
        width: 10vw;
    }

    .onboarding-tab-table .documentupload-in-table {
        width: 17.2vw;
    }

    .onboarding-tab-table .documentupload-in-table .progress,
    .archive-tab-table .documentupload-in-table .progress {
        max-width: 83%;
        height: 8px;
        border-radius: 20px;
    }

    .onboarding-tab-table .applicationsubmiteddate-in-table .form-group,
    .archive-tab-table .applicationsubmiteddate-in-table .form-group {
        margin-bottom: 0px;
    }

    /* Archive page table alignment changes  */
    .archive-tab-table .dealer-details-in-table {
        width: 17vw;
    }

    .archive-tab-table .applicationsubmiteddate-in-table {
        width: 16vw;
    }

    .archive-tab-table .decisiondate-in-table {
        width: 12vw;
    }

    .archive-tab-table .decisionnotes-in-table {
        width: 12vw;
    }

    .archive-tab-table .creditscore-in-table {
        width: 13vw;
    }

    .archive-tab-table .rsm-in-table {
        width: 14vw;
    }

    .archive-tab-table .rsm-in-table .cust-multi-select-box.default-fieldset {
        width: 10vw;
    }

    .archive-tab-table .documentupload-in-table {
        width: 16.2vw;
    }

    /* User management page table alignmnet changes */
    .user-management-tables .fullname-in-table {
        width: 25vw;
    }

    .user-management-tables .account-status-in-table {
        width: 15vw;
    }

    .user-management-tables .user-role-in-table {
        width: 17vw;
    }

    .user-management-tables .contact-info-in-table {
        width: 22vw;
    }

    .user-management-tables .org-in-table {
        width: 18vw;
    }

    .user-management-wrapper .firstname-in-table {
        width: 20vw;
    }

    .user-management-wrapper .lastname-in-table {
        width: 20vw;
    }

    .user-management-wrapper .contactinformation-in-table {
        width: 20vw;
    }

    .user-management-wrapper .otp-in-table {
        width: 20vw;
    }

    .user-management-wrapper .relationship-managers-table .otp-in-table {
        width: 24vw;
    }

    .user-management-wrapper .role-in-table {
        width: 14vw;
    }

    .user-management-wrapper .name-in-table {
        width: 30vw;
    }

    .user-management-wrapper .relationship-managers-table .name-in-table {
        width: 26vw;
    }

    .user-management-wrapper .email-in-table {
        width: 20vw;
    }

    .user-management-wrapper .relationship-managers-table .email-in-table {
        width: 22vw;
    }

    .user-management-wrapper .phone-in-table {
        width: 20vw;
    }

    .user-management-wrapper .relationship-managers-table .phone-in-table {
        width: 17vw;
    }

    .user-management-wrapper .company-in-table {
        width: 18vw;
    }

    /* view loan transactions table alignment changes */
    .viewloan-transaction-table-wrapper .description-in-table {
        width: 18vw;
    }

    .viewloan-transaction-table-wrapper .paymentstatus-in-table {
        width: 14vw;
    }

    .viewloan-transaction-table-wrapper .duedate-in-table {
        width: 14vw;
    }

    .viewloan-transaction-table-wrapper .paiddate-in-table {
        width: 14vw;
    }

    .viewloan-transaction-table-wrapper .paymentdetails-in-table {
        width: 22vw;
    }

    .viewloan-transaction-table-wrapper .transmissionreference-in-table {
        width: 18vw;
    }

    .view-application-documents-table .document-label-in-table {
        width: 20vw;
    }

    .view-application-documents-table .document-attach-in-table {
        width: 20vw;
    }

    .view-application-documents-table .document-uploaddate-in-table {
        width: 9vw;
    }

    .view-application-documents-table .document-submittedby-in-table {
        width: 9vw;
    }

    .view-application-documents-table .document-comments-in-table {
        width: 20vw;
    }

    .view-application-documents-table .document-comments-in-table input {
        width: 80%;
    }

    .view-application-documents-table .document-status-in-table {
        width: 7vw;
    }

    /* Asset History page table alignment changes */
    .asset-history-table-wrapper .user-details-in-table {
        width: 22vw;
    }

    .asset-history-table-wrapper .description-in-table {
        width: 43vw;
    }

    .asset-history-table-wrapper .createddate-in-table {
        width: 19vw;
    }

    .asset-history-table-wrapper .status-in-table {
        width: 16vw;
    }

    /* Lender Portal - Payment Checkout Table */
    .lender-checkout-page-table .expanded-checkout-inner-table.inner-table {
        padding: 0 !important;
    }

    .expanded-checkout-table .dealer-vehicle-details-in-table {
        width: 39vw !important;
    }

    .expanded-checkout-table .accountName-in-table,
    .expanded-checkout-table .accountNumber-in-table {
        width: 18vw !important;
    }

    .table-with-expanded-rows .expanded-checkout-inner-table .dealer-vehicle-details-in-table {
        padding-left: 25px !important;
        width: 40.5vw !important;
    }

    .table-with-expanded-rows .expanded-checkout-inner-table th:first-child {
        padding-left: 25px !important;
    }

    .expanded-checkout-table .scheduledate-in-table {
        width: 25vw !important;
    }

    .expanded-checkout-table .billingamount-in-table {
        width: 25vw !important;
    }

    .expanded-checkout-table .submitdetails-in-table {
        width: 12vw;
    }

    .success-page-table .bank-account-name-in-table {
        width: 30vw;
    }

    .success-page-table .bank-account-number-in-table {
        width: 22vw;
    }

    /* full vehicle details document upload table alignment changes */

    .uploadedDocumentsRow .documenttype-in-table {
        width: 45vw;
    }

    .uploadedDocumentsRow .createddate-in-table {
        width: 25vw;
    }

    .uploadedDocumentsRow .actions-in-table {
        width: 7vw;
    }

    .read-only-access .uploadedDocumentsRow .documenttype-in-table {
        width: 45.5vw;
    }

    .read-only-access .uploadedDocumentsRow .createddate-in-table {
        width: 50.5vw;
    }

    .read-only-access .uploadedDocumentsRow .actions-in-table {
        width: 4vw;
    }

    /* Lender audit page table alignment changes */
    .lender-audit-table-wrapper .dealer-details-in-table,
    .risk-audit-table .dealer-details-in-table     {
        width: 23vw;
    }

    .lender-audit-table-wrapper .auditdate-in-table,
    .risk-audit-table .auditdate-in-table {
        width: 12vw;
    }

    .lender-audit-table-wrapper .found-in-table,
    .lender-audit-table-wrapper .pending-in-table,
    .lender-audit-table-wrapper .sold-in-table,
    .risk-audit-table .found-in-table,
    .risk-audit-table .pending-in-table,
    .risk-audit-table .sold-in-table {
        width: 17vw;
    }

    .lender-audit-table-wrapper .verified-in-table,
    .lender-audit-table-wrapper .unverified-in-table,
    .risk-audit-table .verified-in-table,
    .risk-audit-table .unverified-in-table {
        width: 7vw;
    }

    .lender-audit-table-wrapper .innerTableDimension,
    .risk-audit-table .innerTableDimension {
        /* max-height: 55vh !important; */
        max-height: unset !important;
    }

    .lender-audit-table-wrapper .innerTableDimension .vehicle-details-in-table {
        width: 24vw;
    }

    .risk-audit-table .innerTableDimension .vehicle-details-in-table{
        width: 25vw;
    }

    .lender-audit-table-wrapper .innerTableDimension .audit-status-in-table {
        width: 16vw;
    }

    .risk-audit-table .innerTableDimension .audit-status-in-table {
        width: 13vw;
    }

    .lender-audit-table-wrapper .innerTableDimension .flooreddays-in-table {
        width: 13vw;
    }
    
    .risk-audit-table .innerTableDimension .flooreddays-in-table {
        width: 14vw;
    }

    .lender-audit-table-wrapper .innerTableDimension .followup-in-table,
    .risk-audit-table .innerTableDimension .followup-in-table {
        width: 20vw;
    }

    .lender-audit-table-wrapper .innerTableDimension .followup-in-table p {
        max-width: 76%;
    }
    
    .risk-audit-table .innerTableDimension .followup-in-table p {
        max-width: 86%;
    }

    .lender-audit-table-wrapper .innerTableDimension .verified-in-table,
    .risk-audit-table .innerTableDimension .verified-in-table {
        width: 10vw;
    }

    .lender-audit-table-wrapper .innerTableDimension .notes-in-table {
        width: 13vw;
    }
    
    .risk-audit-table .innerTableDimension .notes-in-table {
        width: 14vw;
    }

    /* lender audit inventory table alignment changes */
    .lender-audit-inventory-table-wrapper .dealer-details-in-table {
        width: 20vw;
    }

    .lender-audit-inventory-table-wrapper .veicle-details-in-table {
        width: 19vw;
    }



    .lender-audit-inventory-table-wrapper .auditstatus-in-table {
        width: 11vw;
    }

    .lender-audit-inventory-table-wrapper .auditdate-in-table {
        width: 12.5vw;
    }

    .lender-audit-inventory-table-wrapper .flooreddays-in-table {
        width: 12.5vw;
    }

    .lender-audit-inventory-table-wrapper .followup-in-table {
        width: 17.5vw;
    }

    .lender-audit-inventory-table-wrapper .followup-in-table p {
        max-width: 92%;
    }

    .lender-audit-inventory-table-wrapper .verified-in-table {
        width: 7.5vw;
    }

    .lender-audit-inventory-table-wrapper .notes-in-table {
        width: 5vw;
    }

    .audits-upcoming-page-table .dealer-details-in-table {
        width: 41vw;
    }

    .audits-upcoming-page-table .audit-type-in-table {
        width: 15vw;
    }

    .audits-upcoming-page-table .audit-status-in-table {
        width: 15vw;
    }

    .audits-upcoming-page-table .scheduled-date-in-table {
        width: 15vw;
    }

    .audits-upcoming-page-table .last-audit-date-in-table {
        width: 15vw;
    }

    /* Audit Notes --> In person */
    .audit-notes-wrapper table th:last-child,
    .audit-notes-wrapper table td:last-child {
        max-width: unset;
        min-width: auto;
        padding: 10px 5px;
    }

    .audit-notes-wrapper .auditor-notes-inPerson {
        width: 25vw;
    }

    /* Lender - Pipelines: Tracker Table columns */

    /* Pipelines - New Application & Document Collection Table */
    .pipeline-tracker-wrapper .new-application-wrapper .dealer-details-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .dealer-details-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .notes-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .notes-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .underwriter-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .underwriter-in-table {
        width: 12vw;
    }

    .pipeline-tracker-wrapper .new-application-wrapper .dealership-license-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .dealership-license-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .business-license-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .business-license-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .driver-license-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .driver-license-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .dealer-insurance-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .dealer-insurance-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .dealer-bond-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .dealer-bond-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .three-months-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .three-months-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .fp-rec-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .fp-rec-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .lot-photo-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .lot-photo-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .voided-check-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .voided-check-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .two-year-busTax-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .two-year-busTax-in-table,
    .pipeline-tracker-wrapper .new-application-wrapper .two-year-perTax-in-table,
    .pipeline-tracker-wrapper .document-collection-wrapper .two-year-perTax-in-table {
        width: 7vw;
    }

    .pipeline-tracker-wrapper .default-table-actions-dd {
        margin-right: 15px;
    }

    /* Pipelines - Underwriting Table */
    .pipeline-tracker-wrapper .underwriting-wrapper .dealer-details-in-table,
    .pipeline-tracker-wrapper .underwriting-wrapper .notes-in-table {
        width: 20vw;
    }

    .pipeline-tracker-wrapper .underwriting-wrapper .underwriter-in-table {
        width: 15vw;
    }

    .pipeline-tracker-wrapper .underwriting-wrapper .underwriting-date-in-table {
        width: 15vw;
    }

    .pipeline-tracker-wrapper .underwriting-wrapper .review-complete-in-table {
        width: 18vw;
    }

    .pipeline-tracker-wrapper .underwriting-wrapper .decision-in-table {
        width: 15vw;
    }

    .pipeline-tracker-wrapper .underwriting-wrapper .document-upload-in-table {
        width: 17vw;
    }

    /* Pipelines - Contracting Table */
    .pipeline-tracker-wrapper .contracting-wrapper .dealer-details-in-table,
    .pipeline-tracker-wrapper .contracting-wrapper .notes-in-table {
        width: 23vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .underwriter-in-table {
        width: 17vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .contracting-date-in-table {
        width: 14vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .review-complete-in-table {
        width: 14vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .decision-in-table {
        width: 14vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .loan-package-sent-in-table {
        width: 17vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .loan-package-signed-in-table {
        width: 17vw;
    }

    .pipeline-tracker-wrapper .contracting-wrapper .document-upload-in-table {
        width: 17vw;
    }

    /* Dealer setup page table alignment changes */


    .supplier-setup-wrapper .SupplierType-in-table {
        width: 15vw;
    }

    .supplier-setup-wrapper .contactInfo-in-table {
        width: 22vw;
    }



    .supplier-setup-wrapper .setup-supplier-column {
        width: 10vw;
    }

    /* full vehicle details payment table alignment changes */
    .fullvehicle-payments-table-wrapper .paymenttype-in-table {
        width: 27vw;
    }

    .fullvehicle-payments-table-wrapper.only-view-access-table .paymenttype-in-table {
        width: 28vw;
    }

    .fullvehicle-payments-table-wrapper .status-in-table,
    .fullvehicle-payments-table-wrapper .duedate-in-table,
    .fullvehicle-payments-table-wrapper .duedate-in-table,
    .fullvehicle-payments-table-wrapper .paiddate-in-table {
        width: 16vw;
    }

    .fullvehicle-payments-table-wrapper.only-view-access-table .paymentamount-in-table {
        width: 20vw;
    }

    .fullvehicle-payments-table-wrapper.only-view-access-table .status-in-table,
    .fullvehicle-payments-table-wrapper.only-view-access-table .duedate-in-table,
    .fullvehicle-payments-table-wrapper.only-view-access-table .duedate-in-table,
    .fullvehicle-payments-table-wrapper.only-view-access-table .paiddate-in-table {
        width: 17vw;
    }

    .fullvehicle-payments-table-wrapper .payments-without-actions .status-in-table {
        width: 18vw;

    }

    .fullvehicle-payments-table-wrapper .payments-without-actions .duedate-in-table {
        width: 20vw;

    }

    .fullvehicle-payments-table-wrapper .payments-without-actions .paiddate-in-table {
        width: 19vw;
    }

    .fullvehicle-payments-table-wrapper .payments-without-actions .paymentamount-in-table,
    .fullvehicle-payments-table-wrapper .payments-without-actions th:last-child,
    .fullvehicle-payments-table-wrapper .payments-without-actions td:last-child {
        width: 18vw;
        min-width: auto !important;
        max-width: unset !important;
        display: table-cell !important;
    }

    /* Acc. Mgmt. Billing - Send Invoice Table */
    .assign-sundry-dealers-popup .dealer-details-in-table {
        width: 40vw;
    }

    .assign-sundry-dealers-popup .invoice-sent-column {
        width: 30vw;
    }

    .assign-sundry-dealers-popup .total-in-table {
        width: 30vw;
    }

    /* Acc. Mgmt. --> Multi Dealers Table */
    .multi-dealers-wrapper .dealer-details-in-table {
        width: 40vw;
    }

    .multi-dealers-wrapper .users-emails-in-table {
        width: 40vw;
    }

    .multi-dealers-wrapper .users-list-in-table {
        width: 38vw;

    }

    /* additional locations table adjustment changes */
    .additional-locations-table .locationname-in-table {
        width: 12vw;
    }

    .additional-locations-table .contactinfo-in-table {
        width: 12vw;
    }

    .additional-locations-table .addressinfo-in-table {
        width: 25vw;
    }

    .additional-locations-table .location-name-in-table {
        width: 16vw;
    }

    .additional-locations-table .contact-name-in-table {
        width: 26vw;
    }

    .additional-locations-table .contact-email-in-table {
        width: 11vw;
    }

    .additional-locations-table .contact-phone-in-table {
        width: 16vw;
    }

    .additional-locations-table .contact-address-in-table {
        width: 18vw;
    }

    .additional-locations-table .contact-locType-in-table {
        width: 10vw;
    }

    .additional-locations-table .perform-audit-in-table,
    .additional-locations-table .audit-cad-in-table,
    .additional-locations-table .primary-loc-in-table {
        width: 9vw;
    }

    .additional-locations-table .rental-contract-in-table,
    .additional-locations-table .dealers-license-in-table {
        width: 9vw;
    }

    .additional-locations-table .dealers-license-in-table span,
    .additional-locations-table .rental-contract-in-table span {
        min-width: 71px;
        display: inline-block;
    }

    .reviewEditTable .additional-locations-table .locationname-in-table {
        width: 23vw;
    }

    .reviewEditTable .additional-locations-table .contactinfo-in-table {
        width: 23vw;
    }

    .reviewEditTable .additional-locations-table .addressinfo-in-table {
        width: 35vw;
    }

    /* Dealer Principal Table */
    .principal-information-table .firstName-in-table {
        width: 10vw;
    }

    .principal-information-table .lastName-in-table {
        width: 10vw;
    }

    .principal-information-table .ownership-in-table {
        width: 7vw;
    }

    .principal-information-table .address-in-table {
        width: 10vw;
    }

    .principal-information-table .phone-in-table {
        width: 9vw;
    }

    .principal-information-table .ssn-in-table {
        width: 9vw;
    }

    .principal-information-table .score-in-table {
        width: 11vw;
    }

    .principal-information-table .email-in-table {
        width: 10vw;
    }

    .principal-information-table .dob-in-table {
        width: 8vw;
    }

    /* bank accounts table */
    .bank-accounts-table .bank-name-in-table {
        width: 24vw;
    }

    .bank-accounts-table .acc-num-in-table {
        width: 17vw;
    }

    .bank-accounts-table .routing-num-in-table {
        width: 17vw;
    }

    .bank-accounts-table .ach-form-in-table {
        width: 13vw;
    }

    .bank-accounts-table .voided-check-in-table {
        width: 14vw;
    }

    .bank-accounts-table .balance-in-table {
        width: 10vw;
    }

    .bank-accounts-table .primary-in-table {
        width: 10vw;
    }

    /* Compliance accounts table */
    .compliance-table .type-in-table {
        width: 24vw;
    }

    .compliance-table .attachments-in-table {
        width: 21vw;
    }

    .compliance-table .expiration-in-table {
        width: 20vw;
    }

    .compliance-table .comments-in-table {
        width: 20vw;
    }

    /* Shipping Address table */
    .address-table .address-one-in-table {
        width: 20vw;
    }

    .address-table .address-two-in-table {
        width: 20vw;
    }

    .address-table .city-in-table {
        width: 20vw;
    }

    .address-table .state-in-table {
        width: 13vw;
    }

    .address-table .zip-code-in-table {
        width: 13vw;
    }

    .address-table .primary-in-table {
        width: 13vw;
    }

    /* contacts table */
    .application-contacts-table .role-in-table {
        width: 20vw;
    }

    .application-contacts-table .name-in-table {
        width: 20vw;

    }

    .application-contacts-table .locations-in-table {
        width: 18vw;

    }

    .application-contacts-table .phone-in-table {
        width: 13vw;

    }

    .application-contacts-table .email-in-table {
        width: 14vw;

    }

    .application-contacts-table .comments-in-table {
        width: 20vw;

    }

    .weekly-performance-table-wrapper.riskdashboard-dealerlist-table p {
        min-height: 30px;
        margin-bottom: 8px !important;
        /* border-bottom: 1px solid #e9e9e9; */
    }

    .weekly-performance-table-wrapper.riskdashboard-dealerlist-table .subcategory-details {
        padding-right: 0px;
    }

    .weekly-performance-table-wrapper.riskdashboard-dealerlist-table .subcategory-details p {
        padding-right: 10px;
    }

    .weekly-performance-table-wrapper .weeklydata-details {
        padding-left: 0px;
    }

    .weekly-performance-table-wrapper .weeklydata-details {
        padding-left: 0px !important;
    }

    .weekly-performance-table-wrapper .weeklywrapper {
        display: flex;
    }

    .weekly-performance-table-wrapper .weeklywrapper p,
    .weekly-performance-table-wrapper .weekNamesWrapper span {
        min-width: 80px;
        max-width: 80px;
        display: inline-flex;
        white-space: break-spaces;
        justify-content: flex-end;
    }

    .weekly-performance-table-wrapper .weekNamesWrapper .weekName {
        min-width: 63px;
        max-width: 63px;
    }

    .weekly-performance-table-wrapper .weekNamesWrapper {
        display: flex;
    }

    .weekly-performance-table-wrapper .table-data-height {
        max-height: 140px;
        overflow: hidden;
    }

    .weekly-performance-table-wrapper.riskdashboard-dealerlist-table .row-item-flex {
        max-width: unset;
    }

    .min-height-50 {
        min-height: 50px;
    }


    /* Plaid weekly report page table alignment changes */

    .weeklyPlaidReport-table-wrapper.riskdashboard-dealerlist-table .dealerdetails-in-table {
        width: 13vw;
    }

    .weeklyPlaidReport-table-wrapper.riskdashboard-dealerlist-table .bankdetails-in-table {
        width: 20vw;
        min-width: auto;
        max-width: unset;
        word-break: break-all;
    }

    .weeklyPlaidReport-table-wrapper.riskdashboard-dealerlist-table .weeklydata-details {
        width: 40vw;
        min-width: auto;
        max-width: unset;
        padding-right: 5vw;
    }

    /* global reports wrapper table adjusment changes */
    .reports-page-global-wrapper .default-table td {
        min-width: 5vw;
    }

    .reports-page-global-wrapper .default-table td.minwidth-in-table {
        min-width: 9vw;
    }

    /*  */
    .dealerSupplier-table-wrapper .dealer-details-in-table {
        width: 50vw;
    }

    .dealerSupplier-table-wrapper .first-name-in-table {
        width: 25vw;
    }

    .dealerSupplier-table-wrapper .last-name-in-table {
        width: 25vw;
    }

    /* multi dealer admin page table alignment changes */
    .mutildealeradmin-wrapper .utilization-in-table {
        width: 28vw;
    }

    .mutildealeradmin-wrapper .utilization-in-table .utlization-wrapper {
        max-width: 75%;
    }

    /* Reconsile TRansaction Report Table alignment changes */
    .reconsile-table-wrapper .dealerdetails-in-table {
        width: 20vw;
    }

    .reconsile-table-wrapper .vehicledetails-in-table {
        width: 20vw;
    }

    /* lender notes table */
    .lender-banner-notes-wrapper .notes-in-table {
        width: 60vw;
    }

    .lender-banner-notes-wrapper .start-date-in-table,
    .lender-banner-notes-wrapper .end-date-in-table {
        width: 30vw;
    }

    .create-banner-wrapper .banner-header-in-table {
        width: 35vw;
    }

    .create-banner-wrapper .subject-in-table {
        width: 30vw;
    }

    .create-banner-wrapper .banner-type-in-table {
        width: 20vw;
    }

    .create-banner-wrapper .task-type-in-table {
        width: 19vw;
    }

    .create-banner-wrapper .start-date-in-table {
        width: 20vw;
    }

    .create-banner-wrapper .end-date-in-table {
        width: 20vw;
    }

    .create-banner-wrapper .lender-banners-table .banner-header-in-table {
        width: 30vw;
    }

    .create-banner-wrapper .lender-banners-table .subject-in-table {
        width: 30vw;
    }

    .create-banner-wrapper .lender-banners-table .start-date-in-table {
        width: 20vw;
    }

    .create-banner-wrapper .lender-banners-table .end-date-in-table {
        width: 20vw;
    }

    /* Reconsile TRansaction Report Table alignment changes */
    .reconsile-table-wrapper .dealerdetails-in-table {
        width: 20vw;
    }

    .reconsile-table-wrapper .vehicledetails-in-table {
        width: 20vw;
    }

    /* Supplier List table columns adjustments */
    .supplier-dealer-list-table .dealer-details-in-table {
        width: 36vw;
    }

    .supplier-dealer-list-table .available-balance-in-table {
        width: 22vw;
    }

    .supplier-dealer-list-table .city-in-table {
        width: 16vw;
    }

    .supplier-dealer-list-table .state-in-table {
        width: 16vw;
    }

    .supplier-dealer-list-table .account-status-table {
        width: 18vw !important;
        max-width: unset !important;
        min-width: unset !important;
        margin-left: unset !important;
        margin-right: unset !important;
        display: table-cell !important;
    }

    .auction-pending-request-table .vehicle-in-table {
        width: 26.2vw;
    }

    .auction-pending-request-table .date-requested-in-table {
        width: 20.2vw;
    }

    .auction-pending-request-table .requested-amount-in-table {
        width: 19.2vw;
    }

    .auction-pending-request-table .date-funded-in-table {
        width: 16.2vw;
    }

    .auction-pending-request-table .vehicle-title-in-table,
    .auction-pending-request-table .funding-status-in-table {
        width: 18.2vw;
    }

    /* Supplier - Funding Req. in Process Table alignments */
    .funding-request-inProcess-table .dealer-details-in-table,
    .supplier-inventory-history .dealer-details-in-table {
        width: 20vw;
    }

    .bos-funding-request-inProcess-table .dealer-details-in-table {
        width: 22vw;
    }

    .bos-funding-request-inProcess-table .date-funded-in-table {
        width: 15vw;
    }

    .funding-request-inProcess-table .vehicle-details-in-table,
    .supplier-inventory-history .vehicle-details-in-table {
        width: 20vw;
    }

    .bos-funding-request-inProcess-table .vehicle-details-in-table {
        width: 23vw;
    }

    .funding-request-inProcess-table .date-requested-in-table,
    .supplier-inventory-history .date-requested-in-table {
        width: 13vw;
    }
    
    .funding-request-inProcess-table .loan-status-in-table,
    .supplier-inventory-history .loan-status-in-table {
        width: 9vw;
    }

    .bos-funding-request-inProcess-table .date-requested-in-table {
        width: 18vw;
    }

    .funding-request-inProcess-table .requested-amount-in-table,
    .supplier-inventory-history .requested-amount-in-table {
        width: 15vw;
    }

    .bos-funding-request-inProcess-table .requested-amount-in-table {
        width: 22vw;
    }

    .funding-request-inProcess-table .date-funded-in-table,
    .supplier-inventory-history .date-funded-in-table {
        width: 10vw;
    }

    .funding-request-inProcess-table .vehicle-title-in-table,
    .supplier-inventory-history .vehicle-title-in-table {
        width: 10vw;
    }

    .funding-request-inProcess-table .title-status-in-table,
    .supplier-inventory-history .title-status-in-table {
        width: 8vw;
    }

    .bos-supplier-inventory-history .dealer-details-in-table {
        width: 23.2vw;
    }

    .bos-supplier-inventory-history .vehicle-details-in-table {
        width: 22.2vw;
    }

    .bos-supplier-inventory-history .date-requested-in-table {
        width: 18.2vw;
    }

    .bos-supplier-inventory-history .requested-amount-in-table {
        width: 18.2vw;
    }

    .bos-supplier-inventory-history .date-funded-in-table {
        width: 18.2vw;
    }

    /* Risk Dashboard - Credit Score Table */
    .credit-score-popup .creditScore-dealer-in-table {
        width: 45vw;
    }

    .credit-score-popup .creditScore-score-in-table {
        width: 25vw;
    }

    .credit-score-popup .creditScore-pullDate-in-table {
        width: 30vw;
    }

    /* external bank selection tables */
    .ach-form-file-in-table {
        min-width: 96px;
        display: block;
    }

    .credit-score-popup .creditScore-score-in-table {
        width: 25vw;
    }

    .credit-score-popup .creditScore-pullDate-in-table {
        width: 30vw;
    }

    /* Dealer Score Table */
    .dealer-score-popup .category-in-table {
        width: 38vw;
    }

    .dealer-score-popup .value-in-table {
        width: 26vw;
    }

    .dealer-score-popup .deduction-in-table {
        width: 25vw;
    }

    /* Supplier bulk floor vehicle table adjustment changes */
    .supplier-bulk-upload-popup .default-table th:first-child,
    .supplier-bulk-upload-popup .default-table td:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #eef0f5;
    }

    .supplier-bulk-upload-popup .default-table td {
        width: 8vw;
    }

    .supplier-bulk-upload-popup .default-table td.purchase-date-in-table {
        width: 16vw;
    }

    .supplier-bulk-upload-popup .default-table td.vehicle-details-in-table {
        width: 14vw;
        background: #eff1f6;
    }

    .supplier-bulk-upload-popup .default-table td.bos-in-table {
        width: 15vw;
    }

    .supplier-bulk-upload-popup .default-table td.bos-in-table>p {
        min-width: 66px;
    }

    .supplier-bulk-upload-popup .default-table td.bos-in-table .edit-bos {
        min-width: 110px;
    }

    .supplier-bulk-upload-popup .additional-documents .vehicle-upload-document-dropdown>button {
        min-width: 188px;
    }

    .dealer-bulk-upload .default-table td {
        width: 10vw;
    }

    .dealer-bulk-upload .default-table .title-in-table {
        width: 9vw;
    }

    .dealer-bulk-upload .default-table .bos-in-table {
        width: 11vw;
    }

    /* Vehicle Registry Report Table */
    .vehicle-registry-report-table .requested-by-in-table {
        width: 20vw;
    }

    .vehicle-registry-report-table .requested-on-in-table,
    .vehicle-registry-report-table .completed-date-in-table {
        width: 16vw;
    }

    .vehicle-registry-report-table .filter-in-table {
        width: 27vw;
    }

    .vehicle-registry-report-table .status-in-table {
        width: 11vw;
    }

    .fully-paid-print-table .vehicle-details-in-print>div,
    .vehicle-transaction-print .vehicle-details-in-print>div,
    .vehicle-past-due-print .vehicle-details-in-print>div {
        max-width: 60px;
    }

    /* ACH Reports Tables */
    .lms-data-ach-wrapper .file-name-in-table {
        width: 45vw;
    }

    .lms-data-ach-wrapper .created-at-in-table {
        width: 30vw;
    }

    /* Auction Access Report */
    .funding-details-report-table .dealer-details-in-table,
    .funding-details-report-table .vehicle-details-in-table {
        width: 29.5vw;
    }

    .funding-details-report-table .floored-date-in-table {
        width: 19.5vw;
    }

    .funding-details-report-table .file-information-in-table {
        width: 24.5vw;
    }

    /* Dealer Profile */
    .menu-open .edit-profile-bankselection .account-name-in-table {
        min-width: 160px;
    }

    .dealer-profile-auctions-table .auction-in-table {
        width: 38.6vw;
    }

    .dealer-profile-auctions-table .address-in-table {
        width: 32.3vw;
    }

    .dealer-profile-auctions-table .contact-in-table {
        width: 26vw;
    }

    .funding-details-report-wrapper .requested-by-in-table {
        width: 18vw
    }

    .funding-details-report-wrapper .requested-on-in-table {
        width: 19vw
    }

    .funding-details-report-wrapper .completed-date-in-table {
        width: 19vw
    }

    .funding-details-report-wrapper .filter-in-table {
        width: 36vw
    }

    .funding-details-report-wrapper .status-in-table {
        width: 9vw
    }

    /* external bank account table */
    .banklist-selection-wrapper .active-check-in-table {
        width: 5vw;
    }

    .banklist-selection-wrapper .account-name-in-table {
        width: 19vw;
    }

    .banklist-selection-wrapper .account-number-in-table {
        width: 14vw;
    }

    .banklist-selection-wrapper .account-balance-in-table {
        width: 16vw;
    }

    .banklist-selection-wrapper .plaid-status-in-table {
        width: 15vw;
    }

    .banklist-selection-wrapper .ach-form-in-table {
        width: 12vw;
    }

    .banklist-selection-wrapper .voided-check-in-table {
        width: 13vw;
    }

    .banklist-selection-wrapper .primary-bank-in-table {
        width: 6vw;
    }

    .sau-report-wrapper .dealer-details-in-print {
        max-width: 250px;
    }

    .sau-report-wrapper .vehicle-details-in-print {
        min-width: 100px;
    }

    .sau-report-wrapper .loan-amount-in-table {
        width: 9vw
    }

    .sau-report-wrapper .loan-amount-in-print,
    .sau-report-wrapper .sau-score-in-table,
    .sau-report-wrapper .sau-downpay-in-table {
        min-width: 100px;
    }

    .sau-report-wrapper .title-status-in-table {
        width: 10vw
    }

    .sau-report-wrapper .payment-status-in-table {
        width: 13vw
    }

    .sau-report-wrapper .sau-status-in-table {
        width: 8vw
    }

    .sau-report-wrapper .sau-bos-date-in-table {
        width: 10vw
    }

    .sau-report-wrapper .sau-score-in-table {
        width: 11vw
    }

    .transaction-summary-table .asset-in-table {
        width: 26vw;
    }

    .transaction-summary-table .due-date-in-table {
        width: 24vw;
    }

    .transaction-summary-table .payment-fileRef-in-table {
        width: 20vw;
    }

    .transaction-summary-table .amount-in-table {
        width: 20vw;
    }

    .transaction-summary-table .paid-amount-in-table {
        width: 20vw;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .transaction-summary-table .paid-amount-in-table .default-fieldset {
        max-width: 80%;
    }

    .submit-payment-page-table .vehicle-details-in-table {
        width: 42vw;
    }

    .submit-payment-page-table .description-in-table {
        width: 38vw;
    }

    .submit-payment-page-table .payment-in-table {
        width: 20vw;
    }

    .failed-transaction-table .vehicle-details-in-table {
        width: 30vw;
    }

    .failed-transaction-table .description-in-table {
        width: 20vw;
    }

    .failed-transaction-table .payment-in-table {
        width: 15vw;
    }

    .failed-transaction-table .reason-in-table {
        width: 35vw;
    }

    .oem-supplier-bulk .default-table td.vehicle-details-in-table {
        width: 26vw;
    }

    .oem-supplier-bulk .default-table .color-in-table,
    .oem-supplier-bulk .default-table .style-in-table,
    .oem-supplier-bulk .default-table .title-in-table {
        width: 17vw;
    }

    .oem-supplier-bulk .default-table .bos-in-table,
    .oem-supplier-bulk .default-table .additional-documents {
        width: 20vw;
    }

    /* Scheduled payments report */
    .scheduled-payments-wrapper .dealer-details-in-table,
    .scheduled-payments-wrapper .asset-details-in-table {
        width: 20vw;
    }

    .scheduled-payments-wrapper .transaction-type-in-table {
        width: 13vw;
    }

    .scheduled-payments-wrapper .payment-type-in-table {
        width: 13vw;
    }

    .scheduled-payments-wrapper .scheduled-date-in-table {
        width: 13vw;
    }

    .scheduled-payments-wrapper .payment-details-in-table {
        width: 10vw;
    }

    .scheduled-payments-wrapper .lms-loanid-in-table {
        width: 13vw;
    }

    .scheduler-inner-jsx .dealer-in-table {
        width: 33vw;
    }

    .scheduler-inner-jsx .failed-in-table {
        width: 33vw;
    }

    .scheduler-inner-jsx .error-in-table {
        width: 33vw;
    }

    .account-manager-table .manager-name-in-table,
    .resource-manager-table .manager-name-in-table {
        width: 28vw;
    }

    .account-manager-table .requirements-in-table,
    .resource-manager-table .requirements-in-table {
        width: 30vw;
    }

    .account-manager-table .type-in-table,
    .resource-manager-table .type-in-table {
        width: 20vw;
    }

    .asset-info-table .uploadedDocumentsRow .documenttype-in-table {
        width: 57vw !important;
    }

    .asset-info-table .uploadedDocumentsRow .createddate-in-table {
        width: 69vw !important;
    }

    .bill-of-sale-report .name-in-table {
        width: 40vw;
    }

    .bill-of-sale-report .upload-in-table {
        width: 20vw;
    }

    .assetbulkUploadPopup .advancedSearchFilters>div.col-md-4{
        max-width: 30.5%;
    }

    .supplier-pending-req-table .dealer-details-in-table {
        width: 31vw;
    }

    .supplier-pending-req-table .vehicle-details-in-table {
        width: 35vw;
    }

    .supplier-pending-req-table .requestedamount-in-table {
        width: 17vw;
    }

    .supplier-pending-req-table .requesteddate-in-table {
        width: 17vw;
    }

    .approve-pending-req-table .dealer-details-in-table {
        width: 20vw;
    }

    .approve-pending-req-table .vehicle-details-in-table {
        width: 20vw;
    }

    .approve-pending-req-table .date-requested-in-table {
        width: 13vw;
    }

    .approve-pending-req-table .requested-amount-in-table {
        width: 15vw;
    }

    .approve-pending-req-table .date-funded-in-table {
        width: 10vw;
    }

    .approve-pending-req-table .vehicle-title-in-table {
        width: 10vw;
    }

    .approve-pending-req-table .title-status-in-table {
        width: 8vw;
    }

}

.default-btn.btn-outline-primary.setup-dealer-btn,
.default-btn.btn-outline-primary.setup-supplier-btn {
    min-width: 120px !important;
    background-color: #243b7f;
    border-color: #243b7f;
    color: #fff;
}


.default-btn.btn-outline-primary.setup-supplier-btn {
    min-width: 126px !important;
}

.audits-asset-info-popup .default-body-wrapper {
    min-height: 100%;
}

.audits-asset-info-popup .default-body-wrapper .white-modal-vehicle-details.mb-4 {
    margin-bottom: 0px !important;
}

.payment-history-popup .table-with-expanded-rows table .data-tooltip table th:first-child,
.payment-history-popup .table-with-expanded-rows table .data-tooltip table td:first-child {
    width: auto !important;
    max-width: unset !important;
    min-width: auto !important;
    display: table-cell !important;
    margin: unset !important;
}

@media(min-height:1080px) {
    .tabs-with-table .tab-content .table_scroller {
        max-height: 95vh;
    }
}

/* @media (max-width: 1920px) {

    .default-table td:first-child,
    .subrows-table .default-table td:nth-child(2),
    .subrows-table .default-table td:nth-child(3),
    .payment-history-popup .default-table td:nth-child(2),
    .payment-history-popup .default-table td:nth-child(3) {
        width: 400px;
    }

    .onboarding-tab-table .default-table td:first-child {
        width: 320px;
    }

    .subscription-management-wrapper .default-table td:nth-child(2),
    .lenderReportsPageWrapper .default-table td:nth-child(2),
    .lenderReportsPageWrapper .default-table td:first-child,
    .dealer-dashboard-page .payments-in-progress-table .default-table td:first-child,
    .dealer-dashboard-page .dealer-payment-history .default-table td:first-child,
    .pipeline-tracker-wrapper .default-table td:nth-child(2),
    .riskdashboard-dealerlist-table .default-table td:nth-child(2),
    .payment-history-popup .default-table td:first-child,
    .servicing-history-popup .default-table td:nth-child(2) {
        width: auto;
    }

    .dealer-dashboard-page .payments-in-progress-table .default-table td:nth-child(2) {
        padding-left: 0px;
    }

    .inventory-table .default-table td:first-child,
    .auditsTableLender .default-table td:first-child {
        width: 420px;
    }

    .inventory-table .default-table td:nth-child(2),
    .auditsTableLender .default-table td:nth-child(2) {
        width: 140px;
    }


    .dealer-dashboard-page .default-table td:first-child,
    .dealer-dashboard-page .payments-in-progress-table .default-table td:nth-child(2),
    .dealer-dashboard-page .dealer-payment-history .default-table td:nth-child(2) {
        width: 450px;
    }


    .dealer-dashboard-page .default-table td:nth-child(2) {
        width: 180px;
    }

    .dealer-setup-wrapper .default-table td:first-child {
        width: 400px;
    }

    .dealer-setup-wrapper .default-table td:nth-child(2) {
        width: 120px;
    }

    .dealer-setup-wrapper .default-table td:nth-child(3) {
        width: 140px;
    }
} */
@media (max-width: 1800px) {


    /* .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: 35vh;
        min-height: 35vh;
    }

    .upcoming-events-section.month_view {
        max-height: 27vh;
        min-height: 27vh;
    }

    .upcoming-events-section.month_view.month-with-six-weeks {
        max-height: 22vh;
        min-height: 22vh;
    } */
    .nav-tabs .nav-link, .nav-pills .nav-link{
        font-size: 14px;
    }
}


@media (max-width: 1599px) {


    .creditline-content-wrapper .creditLineNameInfo .widget-title {
        font-size: 16px;
    }

    .creditline-content-wrapper .infoWidget p {
        font-size: 14px;
    }

    .creditline-content-wrapper .dataTotalCount {
        font-size: 14px;
    }

    .creditline-content-wrapper h6 {
        font-size: 14px;
    }

    .inventory-search-parent .table_scroller {
        max-height: calc(var(--display-height) - calc(var(--info-height) + var(--header-height) + 275px)) !important;
    }

    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 320px) !important;
        min-height: calc(var(--dashBoardPage-height) - 320px) !important;
    }

    .auction-notification-widget.dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 260px) !important;
        min-height: calc(var(--dashBoardPage-height) - 260px) !important;
    }

    .supplierDashboardChart {
        max-height: calc(var(--dashBoardPage-height) - 420px) !important;
        min-height: calc(var(--dashBoardPage-height) - 420px) !important;
    }

    .supplierDashboard .history-graph-section {
        margin: 0 0 5px 0;
    }

    input[type='text'],
    input[type='date'],
    input[type='number'],
    input[type='email'],
    input {
        font-size: 12px;
    }

    .notes-in-table-col span img {
        width: 12px;
    }

    .resend-otp-icon {
        width: 14px;
    }

    .setup-wrapper .table-filter-actions {
        right: 184px;
    }

    .creditLineNameInfo .widget-title {
        font-size: 16px;
    }

    .creditLineShortDataInfo .infoWidget {
        padding: 19px 4px !important;
    }

    .creditLineProgressInfo .info-widget-row {
        margin-top: 12px !important;
        margin-left: 0;
    }

    .creditLineProgressInfo .infoWidget {
        padding: 10px 4px 0px 0 !important;
    }

    .creditLineShortDataInfo .infoWidget p {
        font-size: 16px;
        margin-bottom: -1px;
    }

    .creditLineProgressBar {
        height: 14px;
    }

    .creditLineDataGrid .dataTotalCount {
        font-size: 16px;
    }

    .footer-info-widget .infoWidget p {
        font-size: 16px;
    }

    .overlappedProgressData h6 {
        font-size: 12px;
    }

    .footer-info-widget {
        flex-wrap: nowrap;
    }

    .footer-info-widget>div {
        padding-left: 9px !important;
    }

    .footer-info-widget .col:first-child {
        margin-left: -20px;
    }

    .footer-info-widget p {
        font-size: 16px;
    }

    .creditLineDataGrid .dataTotalCount {
        padding: 10px 8px;
    }

    /* .default-table td:first-child,
    .subrows-table .default-table td:nth-child(2),
    .subrows-table .default-table td:nth-child(3),
    .payment-history-popup .default-table td:nth-child(2),
    .payment-history-popup .default-table td:nth-child(3) {
        width: 300px;
    }

    .onboarding-tab-table .default-table td:first-child {
        width: 300px;
    }

    .subscription-management-wrapper .default-table td:nth-child(2),
    .lenderReportsPageWrapper .default-table td:nth-child(2),
    .lenderReportsPageWrapper .default-table td:first-child,
    .dealer-dashboard-page .payments-in-progress-table .default-table td:first-child,
    .dealer-dashboard-page .dealer-payment-history .default-table td:first-child,
    .pipeline-tracker-wrapper .default-table td:nth-child(2),
    .servicing-history-popup .default-table td:nth-child(2) {
        width: auto;
    }

    .dealer-dashboard-page .payments-in-progress-table .default-table td:nth-child(2) {
        padding-left: 0px;
    }

    .inventory-table .default-table td:first-child,
    .auditsTableLender .default-table td:first-child {
        width: 400px;
    }

    .inventory-table .default-table td:nth-child(2),
    .auditsTableLender .default-table td:nth-child(2) {
        width: 140px;
    }


    .dealer-dashboard-page .default-table td:first-child,
    .dealer-dashboard-page .payments-in-progress-table .default-table td:nth-child(2),
    .dealer-dashboard-page .dealer-payment-history .default-table td:nth-child(2) {
        width: 380px;
    }


    .dealer-dashboard-page .default-table td:nth-child(2) {
        width: 180px;
    }

    .dealer-setup-wrapper .default-table td:first-child {
        width: 310px;
    }

    .dealer-setup-wrapper .default-table td:nth-child(2) {
        width: 120px;
    }

    .dealer-setup-wrapper .default-table td:nth-child(3) {
        width: 140px;
    } */


    .asset-tag-item small {
        color: #fff !important;
        font-size: 10px;
        line-height: 17px;
    }

    .asset-tag-item {

        line-height: 15px;
    }

    .nav-tabs .nav-link,
    .nav-pills .nav-link {
        margin-right: 23px;
        font-size: 12px !important;
    }

    .vin-search input {
        font-size: 12px;
    }

    p,
    a,
    .default-table p,
    .default-table b,
    .default-table .cs-rsm-dropdown,
    .default-table button,
    .default-table .default-fieldset input .default-table .default-fieldset input,
    .default-table-actions-dd .dropdown-item,
    .default-table input,
    table.default-table textarea,
    table.default-table select,
    table .table-filters input {
        font-size: 12px;
    }

    .semi-heading-intable {
        font-size: 12px !important;
    }

    .stick-label-button {
        font-size: 10px !important;
        line-height: 15px;
    }

    .f-16-400 {
        font-size: 14px;
        font-weight: 400;
    }

    h6 {
        font-size: 14px;
    }

    .p-30-20px {
        padding: 20px 20px;
    }

    .back-link-in-header {
        position: absolute;
        top: 16px;
        right: 65px;
    }

    table.default-table input {
        padding: 9px !important;
    }

    .create-dealer-body .dealer-form-fields>div {
        flex: 1 1 22%;
        max-width: 22%;
    }

    .default-table-actions-dd .dropdown-item,
    .default-table .cs-rsm-dropdown {
        font-size: 11px;
    }

    .default-table-actions-dd .dropdown-item {
        padding: 10px 16px;
    }

    .bank-rotate-refresh,
    .delete-icon {
        width: 11px;
    }

    .task-tooltip-icon,
    .edit-icon-in-table,
    .eyeIcon-in-table,
    .dealer-details-edit-icon,
    .img-12px {
        width: 12px
    }

    .img-13px {
        width: 13px
    }

    .info-icon-in-table,
    .img-14px {
        width: 14px;
    }

    .img-15px,
    .history-icon-in-table {
        width: 15px
    }

    .viewNotes-in-table {
        width: 10px;
    }

    /* .default-table .table-header-bg-color th {
        font-size: 10px;
    } */

    .default-table td {
        font-size: 12px;
    }

    h1 {
        font-size: 19.4px;
        line-height: 40px;
    }

    .primary-level-tabs {
        margin-bottom: 22px;
    }

    .tabs-with-table .nav-tabs .nav-link:first-child,
    .primary-level-tabs.nav-tabs .nav-link:first-child {
        padding-left: 15px !important;
    }

    .tabs-with-table .nav-tabs {
        padding: 16px 0px;
    }

    .tabDataCount {
        font-size: 8px;
        min-width: 18px;
        max-height: 13px;
        padding: 0px 4px !important;
        line-height: 13px;
    }

    .default-table td:first-child,
    .default-table .table-header-bg-color th:first-child {
        padding-left: 17px;
    }

    .default-table .table-header-bg-color th {
        font-size: 10px;
        line-height: 14px;
    }

    .expanded-checkout-table .table-header-bg-color th legend.default-fieldset-label {
        font-size: 10px !important;
        line-height: 14px !important;
    }

    .riskIndicator {
        min-width: 8px;
        max-width: 8px;
        min-height: 8px;
        max-height: 8px;
    }

    .title-status,
    .title-text {
        font-size: 10px !important;
        line-height: 15px;
    }

    .default-table td small {
        font-size: 12px;
    }

    .red-status-pill small,
    .brownish-status-pill small,
    .green-status-pill small,
    .other-status-pill small,
    .blue-status-pill small {
        font-size: 10px !important;
    }

    .default-status-pill small {
        font-size: 10px !important;
    }


    .data-tooltip table td {
        font-size: 10px !important;
    }

    .pipeline-tracker-wrapper .title-status,
    .pipeline-tracker-wrapper .title-text {
        font-size: 9px !important;
    }

    .data-tooltip td:last-child,
    .data-tooltip th:last-child {
        min-width: auto !important;
        max-width: 100% !important;
    }

    .default-btn {
        min-width: 80px;
    }

    .default-btn.btn-outline-secondary.no-bg-on-hover,
    .default-btn {
        min-height: 35px;
    }

    .auctionAccessTab .auction-access-show-pill,
    .bos-bank-showdays .auction-access-show-pill {
        top: 13px;
        left: 635px;
    }

    .menu-open .auctionAccessTab .auction-access-show-pill {
        position: absolute;
        top: 13px;
        left: 568px;
    }

    .partner-table-filters .table-filter-actions {
        z-index: 1;
        top: -46px;
        right: 16px;
    }

    .reconsile-table-wrapper .table-filter-actions {
        right: 17px;
    }

    .create-file-btn {
        top: 3px;
        right: -155px;
    }

    small.red-status-pill,
    small.green-status-pill,
    small.brownish-status-pill,
    small.other-status-pill,
    small.blue-status-pill,
    small.purple-status-pill {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    small.default-status-pill {
        font-size: 10px !important;
        line-height: 14px !important;
    }

    .frozen-message {
        padding: 12px;
    }

    .frozen-message img {
        width: 18px;
        height: 18px;
        margin: 0 10px 0 0;
    }

    /* .table-filter-actions .action-item .table-filter-history-icon {
        width: 21px !important;
    }

    .table-filter-actions .action-item .table-filter-funding-icon {
        width: 17px !important;
    }

    .table-filter-actions .action-item .table-filter-refresh-icon {
        width: 19px !important;
    } */

    .data-tooltip table td,
    .data-tooltip table th {
        font-size: 10px !important;
    }

    .paymentsdue-wrapper .riskIndicator {
        margin-top: 3px;
    }

    .table-filters .filtes-calenderIcon {
        min-width: 135px;
        max-width: 135px;
    }

    .default-table .react-table-datalist-selector .riskIndicator {
        margin: 3.5px 4px 0 0px;
    }

    .upload-input-box {
        padding: 10px 15px;
    }

    .common-info-icon,
    .img-13px {
        width: 13px;
    }

    .title-with-underline {
        font-size: 14px;
        padding: 0 0 4px 0;
    }

    /* .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: 38vh;
        min-height: 38vh;
    }

    .upcoming-events-section.month_view {
        max-height: 27vh;
        min-height: 27vh;
    }

    .upcoming-events-section.month_view.month-with-six-weeks {
        max-height: 22vh;
        min-height: 22vh;
    } */

    .facility-details-incards img {
        width: 38px;
        height: 38px;
    }

    .dealer-dashboard-page .padding-20 {
        padding: 12px;
    }


    .f-24-700 {
        font-size: 22px;
    }

    .plaid-report table .row-item-flex {
        max-width: 460px !important;
    }

    .menu-open .plaid-report table .row-item-flex {
        max-width: 311px !important;
    }

    .menu-open .weekly-performance-table-wrapper.riskdashboard-dealerlist-table .row-item-flex {
        max-width: 400px !important;
    }

    .weeklyPlaidReport-table-wrapper.riskdashboard-dealerlist-table .weeklydata-details {
        padding-right: 0px;
    }

    .weekly-performance-table-wrapper .expandtableArrow {
        left: 90px;
        top: 3px;
    }

    .notes-html-parse * {
        font-size: 12px !important;
        margin: 0 !important;
    }

    .dealer-list-popup-filter .table-filter-actions,
    .dealer-score-popup-filter .table-filter-actions,
    .table-filter-onHeader .table-filter-actions {
        top: -4px;
        z-index: 4;
    }

    .notes-html-parse * {
        font-size: 12px !important;
        margin: 0 !important;
    }

    .info-dropdown {
        margin: -3px 0 0 0;
    }

    .first-col-check-box-table th:first-child .input-label-wrap,
    .first-col-check-box-table td:first-child .input-label-wrap,
    .fedexPrintLabelTable td:first-child .input-label-wrap,
    .fedexPrintLabelTable .table-header-bg-color th:first-child .input-label-wrap {
        width: 14px;
        height: 14px;
    }

    .inventoryWidgetLender .inventoryShowDaysLabel {
        left: 409px;
    }

    .menu-open .inventoryWidgetLender .inventoryShowDaysLabel {
        left: 410px;
    }

    .inventoryWidgetLender .inventoryShowDaysLabel {
        top: 14px;
    }

    .checkout-arrows {
        margin: -5px 0 0 0;
        width: 20px;
        height: 20px;
    }

    .weekly-performance-table-wrapper .weeklywrapper p,
    .weekly-performance-table-wrapper .weekNamesWrapper span {
        min-width: 60px;
        max-width: 60px;
        margin-right: 4px;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .nav-tabs .nav-link {
        margin-right: 23px;
        width: unset;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .nav-tabs .nav-link .tabName {
        display: unset;
    }

    .menu-open .vehicle-reports-wrapper.tabs-with-table .table-filter-actions {
        top: 5px;
    }

    .achCreditDebitRefresh.create-file-btn.advanced-filters-open {
        top: -265px;
    }
    .credit-scheduled-table-wrapper .table-filter-actions {
        top: -45px;
    }
    .transaction-summary-table .default-table .actions-in-table {
        max-width: 90px !important;
        min-width: 90px !important;
    }


    .view-form-label,
    .view-form-value {
        font-size: 12px !important;
    }

    .summary-header p {
        font-size: 10px;
        padding: 9.5px 0;
    }

}

@media (max-width: 1440px) {

    .auction-notification-widget.dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 220px) !important;
        min-height: calc(var(--dashBoardPage-height) - 220px) !important;
    }

    .dealer-dashboard-page .padding-20 {
        padding: 10px;
    }


    .event-calendar-header {
        padding: 10px 10px 0;
    }

    .facility-details-incards img {
        width: 30px;
        height: 30px;
    }

    .upcoming-events-section {
        max-height: 45vh;
    }

    /* .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: 32vh;
        min-height: 32vh;
    }

    .upcoming-events-section.month_view {
        max-height: 22vh;
        min-height: 22vh;
    }

    .upcoming-events-section.month_view.month-with-six-weeks {
        max-height: 17vh;
        min-height: 17vh;
    } */

    .pill-text {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .menu-open .lender-transaction-table .table_scroller {
        max-height: 460px !important;
    }

}

@media (max-width: 1366px) {



    .footer-info-widget .infoWidget .info-icon img {
        max-width: 17px;
    }

    .infoWidget .info-icon img {
        max-width: 20px;
    }
}

@media (max-width: 1349px) {

    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 260px) !important;
        min-height: calc(var(--dashBoardPage-height) - 260px) !important;
    }

    .menu-open .payments-widget-table .nav-tabs,
    .menu-open .inventoryWidgetLender .nav-tabs {
        align-items: flex-end;
    }

    .menu-open .payments-widget-table .nav-tabs .nav-link,
    .menu-open .payments-widget-table .nav-pills .nav-link {
        /* max-width: 12%; */
        /* flex: 1 1 12%; */
    }

    /* .menu-open .inventoryWidgetLender .nav-tabs .nav-link,
    .menu-open .inventoryWidgetLender .nav-pills .nav-link {
        max-width: 9%;
    } */

    .creditLineProgressBar {
        height: 12px;
    }

    .overlappedProgressData h6 {
        font-size: 11px;
    }

    .menu-open .inventoryWidgetLender .expandview-table .nav-item {
        margin-right: 12px;
    }

    .default-popup .table_scroller {
        overflow-y: auto !important;
        max-height: 68vh;
    }

    .four-fields-row>div:nth-child(3n) {
        margin-right: 0;
    }

    .four-fields-row>div {
        min-width: 31.5%;
        max-width: 31%;
        flex: 1 1 31.5%;
        padding: 0 !important;
        margin: 0 15px 15px 0;
    }

    .four-fields-row>div:nth-child(4n) {
        margin-right: 15px !important;
    }

    .fedex-popup.default-popup .table_scroller {
        max-height: 48vh !important;
    }

    /* .shared-notes-table .table-overflow-visible,
    .onboarding-tab-table .table-overflow-visible,
    .archive-tab-table .table-overflow-visible {
        overflow-x: visible !important;
        overflow-y: visible !important;
    } */

    .inventory-note-table-filters {
        position: relative;
    }

    .lender-inventory-table .multi-step-notes-tab .shared-notes-table .table-filter-actions,
    .inventory-note-table-filters .table-filter-actions,
    .menu-open .lender-inventory-table .multi-step-notes-tab .shared-notes-table .table-filter-actions,
    .menu-open .inventory-note-table-filters .table-filter-actions {
        top: 4px;
        right: 17px;
    }

    .plaid-report table .row-item-flex {
        max-width: 350px !important;
    }

    .menu-open .plaid-report table .row-item-flex {
        max-width: 201px !important;
    }

    .inventoryWidgetLender .inventoryShowDaysLabel {
        left: 409px;
    }

    .menu-open .inventoryWidgetLender .inventoryShowDaysLabel {
        left: 378px;
        top: 16px;
    }


    .menu-open .inventoryWidgetLender .nav-tabs .nav-link,
    .menu-open .inventoryWidgetLender .nav-pills .nav-link {
        margin-right: 11px;
    }

    .menu-open .inventoryWidgetLender .table-filter-actions .vin-search input {
        width: 152px;
    }

    .menu-open .inventoryWidgetLender .inventoryShowDaysLabel.default-pill {
        font-size: 10px;
    }

    .menu-open .inventoryWidgetLender .table-filter-actions .action-item {
        padding: 7px 4px;
    }

    .menu-open .inventoryWidgetLender .tabs-with-table .nav-tabs .nav-link:first-child {
        padding-left: 8px !important;
    }

    .menu-open .auctionAccessTab .auction-access-show-pill {
        left: 565px;
        font-size: 9px;
        top: 14px;
    }
}

@media (max-width: 1300px) {
    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: calc(var(--dashBoardPage-height) - 280px) !important;
        min-height: calc(var(--dashBoardPage-height) - 280px) !important;
    }

    /* .menu-open .auctionAccessTab .nav-tabs .nav-link {
        flex: 0 1 6.7%;
    } */
}

@media(max-width:1199px) {

    .four-fields-row>div,
    .three-fields-row>div {
        min-width: 47%;
        max-width: 49%;
        padding: 0 !important;
        margin: 0 15px 15px 0;
    }

    .four-fields-row>div:nth-child(4n),
    .three-fields-row>div:nth-child(3n) {
        margin: 0 15px 15px 0 !important;
    }

    .four-fields-row>div:nth-child(even),
    .three-fields-row>div:nth-child(even) {
        margin-right: 0 !important;
    }

    .default-popup .modal-dialog,
    .default-large-popup .modal-dialog,
    .default-medium-popup .modal-dialog,
    .default-small-popup .modal-dialog,
    .default-large-popup .modal-dialog {
        max-width: 90% !important;
        margin-left: auto;
        margin-right: auto;
    }

    /* .shared-notes-table .table-overflow-visible,
    .onboarding-tab-table .table-overflow-visible,
    .archive-tab-table .table-overflow-visible {
        overflow-x: auto !important;
        overflow-y: auto !important;
    } */

    .historical-graph-previous-arrow {
        left: 43px;
    }

    .menu-open .historical-graph-previous-arrow,
    .menu-open .historical-graph-next-arrow {
        bottom: 75px;
    }

    .historical-graph-next-arrow {
        right: 7px;
    }
}

@media (min-width: 767px) {

    .primary-level-tabs a img,
    .primary-level-tabs a svg {
        display: none;
    }

    .create-user-contract-details .table-responsive {
        overflow: visible;
    }
}

@media (max-width: 767px) {
    .default-btn {
        padding: 5px 5px;
        font-size: 13px;
        min-height: 30px;
    }

    .external-bank-row {
        margin-bottom: 0 !important;
    }

    .external-bank-details>div {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .external-bank-row .bank-actions-block {
        padding: 0;
    }

    .external-bank-list {
        border-bottom: 1px solid #E6E8F0;
    }

    .p-16-20px {
        padding: 10px;
    }

    .two-fields-row>div {
        min-width: 100%;
        max-width: 100%;
        margin: 0 0px 15px 0;
    }

    .primary-level-tabs {
        margin: 0 -18px 22px;
        padding: 8px 18px 1px;
        border-top: 1px solid #E6E8F0;
    }

    .tabs-with-table .nav-tabs .nav-link .tabName,
    .primary-level-tabs.nav-tabs .nav-link {
        text-align: center;
        font-size: 10px;
        color: #757A82;
        font-weight: 400;
        margin: 0;
        flex: 1 1;
    }

    .tabs-with-table .nav-tabs .nav-link.active .tabName {
        font-weight: 700;
        color: #243b7f;
    }

    .tabs-with-table .nav-tabs .nav-link .tabName {
        font-size: 12px;
        line-height: 17px;
    }

    .primary-level-tabs.nav-tabs .nav-link:first-child {
        padding: 0 !important;
    }

    .primary-level-tabs.nav-tabs .nav-link span.widgetTitleName {
        line-height: 16px;
        padding: 0 0 6px 0 !important;
        display: inline-block !important;
    }

    .primary-tabs-icons {
        min-height: 20px;
    }

    h1 {
        font-size: 15px;
    }

    h6 {
        font-size: 16px;
    }

    .padding-20 {
        padding: 15px;
    }

    b,
    a,
    p,
    .calender-dates .calendar-dates span,
    .show-more-dropdown .default-table-actions-dd .dropdown-item {
        font-size: 14px;
    }

    .f-24-700 {
        font-size: 22px;
    }

    .default-popup .modal-title {
        font-size: 16px;
    }

    .default-popup .popup-title-icon img {
        width: 20px;
    }

    .popup-title-icon {
        margin-right: 8px;
    }

    .alert-count {
        height: 20px;
        width: 20px;
        line-height: 20px;
        margin: 0 0 0 6px;
        font-size: 12px;
    }

    .default-btn {
        padding: 7px 20px;
    }

    /* .white-component-modal {
        box-shadow: none;
        border: 1px solid #E6E8F0;
    } */

    .myinventory-table-header,
    .pending-request-table-header {
        padding: 15px 8px 15px 20px;
    }

    .myinventory-table-header h6,
    .pending-request-table-header h6 {
        font-size: 14px;
    }

    .myinventory-table-header .table-filter-actions,
    .pending-request-table-header .table-filter-actions {
        padding: 0;
        border: 0;
    }

    .myinventory-table-header .table-filter-actions button.default-btn,
    .pending-request-table-header .table-filter-actions button.default-btn {
        flex-direction: column;
        font-size: 10px;
        font-weight: 400;
        padding: 2px 5px;
        margin: 0 20px 0 0;
    }

    .myinventory-table-header .table-filter-actions button.default-btn img,
    .pending-request-table-header .table-filter-actions button.default-btn img {
        width: 24px !important;
        height: 8px !important;
    }

    .myinventory-table-header .table-filter-static {
        min-width: 70%;
    }

    .default-popup .modal-dialog,
    .default-large-popup .modal-dialog,
    .default-medium-popup .modal-dialog,
    .default-small-popup .modal-dialog,
    .default-large-popup .modal-dialog {
        max-width: 100% !important;
        margin: 0 10px;
    }

    .advancedSearchFilters {
        padding: 15px 15px 20px 15px !important;
    }

    .complete-payment-btn .number-count-value, .number-count-value {
        min-width: 12px;
        font-size: 8px;
        line-height: 12px;
        left: 10px;
        top: 1px;
    }

    .modify-datepicker-position .react-datepicker-popper {
        left: 50% !important;
        transform: translate3d(-50%, 190px, 0px) !important;
    }

    .primary-level-tabs.nav-tabs .nav-link.active span::after {
        display: none;
    }

    .primary-level-tabs.nav-tabs .nav-link.active .widgetTitleName::after {
        content: "";
        width: 100%;
        height: 2px;
        background: #243B7F;
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: inline-block;
    }

    .dealer-dashboard-page .padding-20.facility-graph-row {
        padding: 17px;
    }

    .banklist-selection-wrapper .external-bank-list {
        padding: 0 0 10px 0;
        margin: 13px 20px 10px 20px !important;
    }

    .external-connect-plaid {
        margin: 10px 0 0 0;
    }

}

/* Failed payments section in dealer checkout page */
.failed-payment-asset {
    width: 25vw;
}

.failed-payment-date {
    width: 25vw;
}

.failed-payment-amount {
    width: 20vw;
}

.failed-payment-reason {
    width: 30vw;
}

/* @media (max-height: 818px) {
    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: 33vh;
        min-height: 33vh;
    }

    .upcoming-events-section.month_view {
        max-height: 32vh;
        min-height: 32vh;
    }

    .upcoming-events-section.month_view.month-with-six-weeks {
        max-height: 25vh;
        min-height: 25vh;
    }
}

@media (min-height: 819px) {
    .dealer-notification-widget .dealer-notifcations .scroll-content {
        max-height: 33vh;
        min-height: 33vh;
    }

    .upcoming-events-section {
        padding: 20px;
    }

    .upcoming-events-section.month_view {
        max-height: 32vh;
        min-height: 32vh;
    }

    .upcoming-events-section.month_view.month-with-six-weeks {
        max-height: 28vh;
        min-height: 28vh;
    }
} */

/*  Responsive styling end here  */
/* Color pallet for our lever application here */

/* Background colors here */

.page-body-bg-color,
.page-wrapper,
.top-list-bg,
.bg-F3F4F6 {
    background-color: #F3F4F6 !important;
}

.darkblue-bg-color {
    background-color: #111827;
}

.primary-button-bg-color,
.primary-blue-bg {
    background-color: #243B7F;
}

.modal-backdrop-bg-color {
    background: rgba(0, 0, 0, 0.54);
}

.default-fieldset-label {
    color: #111111;
}

.white-text {
    color: #fff;
}

.white-text-important {
    color: #fff !important;
}

.default-fieldset {
    border-color: #D1D5DB !important;
}

.default-fieldset input {
    color: #111111;
}

.blue-link-text {
    color: #243B7F !important;
}

.default-table small,
.secondary-text-color small,
.default-table input.secondary-text-color,
.secondary-text-color,
.expanded-row-icon,
.column-sort-icon {
    color: #757A82;
}

.default-table p.secondary-text-color,
p.secondary-text-color,
.notes-in-table-col span {
    color: #757A82 !important;
}

.default-table p,
.primary-text-color,
.primary-text-color,
.black-link,
.time-in-balck-color {
    color: #111111 !important;
}

.primary-blue-color {
    color: #243B7F !important;
}

.disabled-text {
    color: #3741517A;
}

.nav-item.active .tabDataCount {
    background: #243B7F;
}

.icon-color,
.color-9E9E9E {
    color: #9E9E9E;
}

.border-9e9e9e {
    border-color: #9e9e9e;
}

.progress-bar {
    background-color: #243B7F;
}

.other-status-pill,
.default-status-pill {
    background-color: #9E9E9E;
}

.default-status-color {
    color: #9E9E9E !important;
}

.green-status-color {
    color: #0E8074 !important;
}

.green-status-pill,
.bg-0E8074 {
    background-color: #0E8074 !important;
}

.blue-status-pill,
.bg-3832A0 {
    background-color: #3832A0;
}

.red-status-color {
    color: #A10F0F !important;
}

.red-status-pill,
.bg-A10F0F {
    background-color: #A10F0F !important;
}

.brownish-status-color {
    color: #A67905;
}

.brownish-status-pill,
.bg-A67905 {
    background-color: #A67905 !important;
}

.purple-status-pill,
.bg-7C067E {
    background-color: #7C067E;
}

.table-header-bg-color,
.greyish-bg-color {
    background-color: #eff1f6 !important;
}

.high-level-risk-bg-color {
    background-color: #A10F0F;
}

.medium-level-risk-bg-color {
    background-color: #A67905;
}

.low-level-risk-bg-color {
    background-color: #0E8074;
}

.default-level-risk-bg-color {
    background-color: #9E9E9E;
}

.bg-transparent {
    background-color: transparent;
}

.color-52575C {
    color: #52575C;
}

.progress-bar,
.bg-243B7F {
    background-color: #243B7F;
}

.login-from {
    max-width: 600px;
    margin: auto;
    padding: 32px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    min-height: 600px;
    display: flex;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.login-from .site-logo {
    width: 62px;
    display: block;
    margin: auto;
    margin-bottom: 21px;
}

.site-title {
    font-size: 24px;
    font-weight: 700;
    color: #111111;
    line-height: 33px;
    margin-bottom: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.full-width {
    width: 100% !important;
}

.login-from h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.4px;
    color: #111111;
    margin-bottom: 8px;
}

.login-from h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 20px;
    color: #757A82;
}

.login-from label {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
}

.login-from input[type='email'],
.login-from input[type='password'] {
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.15px;
    outline: none !important;
}

.login-error-feedback {
    font-size: 80%;
    color: #dc3545;
    margin-top: 10px;
    margin-bottom: 5px;
}

.btn-before-login {
    box-shadow: none !important;
    width: 100%;
    padding: 0px;
    height: 42px;
    line-height: 42px;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
}

.login-other-action-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #243B7F;
}

.login-other-action-block {
    text-align: right;
    margin-bottom: 15px;
}

.login-page-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    /* background: url('../../assets/images/Login-background-image.svg') #E5E5E5 top center no-repeat; */
    /* background-color: #E3E3E3; */
}

.login-page-wrapper .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.login-page-wrapper .default-btn {
    padding: 11px 18px;
}

.signup-btn {
    border: 1px solid #243B7F;
    padding: 10px 18px;
    text-decoration: none !important;
    border-radius: 8px;
    display: block;
    margin: auto;
    color: #243B7F !important;
    font-size: 14px;
    font-weight: 600;
}

input[type='password']:focus,
input[type='email']:focus {
    outline: none !important;
    box-shadow: none !important;
}

input:invalid {
    box-shadow: 0px 1px 0px #A10F0F;
}

input:valid {
    box-shadow: none;
}

@media (max-width: 1599px) {
    .login-from {
        max-width: 500px;
        min-height: auto;
    }

    .site-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .login-from h2 {
        font-size: 20px;
    }

    .login-from h5 {
        font-size: 12px;
    }

    .signup-btn,
    .login-page-wrapper .default-btn {
        font-size: 13px;
        padding: 8px 18px;
    }

    .login-other-action-link {
        font-size: 13px;
    }

}

@media (max-width: 767px) {
    .login-from {
        max-width: 100%;
        padding: 20px;
        min-height: 100%;
    }

    .login-page-wrapper .container {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .loginWelcomText {
        font-size: 19px !important;
        margin-bottom: 0 !important;
    }
}
.toast {
    max-width: 450px;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 4.8px;
    z-index: 1051;
}

.toast-header {
    height: 62px;
    line-height: 62px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding: 10px 18px;
    border: 0px;
}

.toast-body {
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #111;
}

.toast-body::first-letter {
    text-transform: capitalize;
}

.close {
    opacity: 1;
    font-size: 0px !important;
    width: 15px;
    height: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA7CAYAAAAq55mNAAABU0lEQVRogeXb2Q2DMBAE0C2BUiiFEtIZJVAKpVDCREhsZBEO43PNzG+M5afEBOy17AOgAzAD+Px9aDzrmLexd5cjdZCaZrAbUnOOPUA2g90hz7EXSPPYE+QxFsB00dgs9gapmbTx6NHYHNYTqRnl5idrEvsQuWb2mZ+msCHI3zxtBRuFdDoxjU2CtI5NirSKzYK0hs2KtIItgqyNLYqsha2CLI2tiozEDg/6H6ojI7ALgN6j335rWx+ZC2sSmRprGpkK2wQyFtsU0sE+HfQS0P72hlYkAdj2kJoMWHtITUKsXaQmAdY+UhOBbQepCXhAR3MbWxTfKMUcpbjrUvyPUjwZUTzrUry9ULyPUqwwUKwZUawCUqzrUqzUU+y9UOymUeyPUux4U9QwUFSlUNQZUVSO0dQCMlV3ctTrCksFtjDV1AvLKQnngvefe3EueN9JJhH5AlDTEWpdbHudAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0px !important;
}

.error-toaster .toast-header {
    background-color: #A10F0F;
}

.success-toaster .toast-header {
    background-color: #0E8074;
}

.warning-toaster .toast-header {
    background-color: #A67905;
}

@media (max-width: 767px) {
    .toast {
        width: 97%;
        right: 5px !important;
        left: auto !important;
        margin: 0 auto !important;
    }
}
.app-loader {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    z-index: 99;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.app-loader img {
    max-width: 70px !important;
}

.app-loader.fixed {
    position: fixed;
}

.app-loader.absolute {
    position: absolute;
}

img.loader-gif-icon {
    width: 52px;
}

@media(max-width: 992px) {
    img.loader-gif-icon {
        width: 47px;
    }
}

@media(max-width: 767px) {
    img.loader-gif-icon {
        width: 43px;
    }
}
/* All input css start here  */
.form-control:disabled,
.disabled-select-box-with-icon .react-select-dealerlist,
fieldset.disabled-form-field input {
    background-color: #F3F4F6 !important;
}

.default-fieldset {
    margin: 0px;
    padding: 0px;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    /* height: 100%; */
    /* transition: all ease-in-out 0.5s; */
}

fieldset:focus-within {
    border: 2px solid #243b7f !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.05);
}

fieldset.default-checkbox:focus-within,
fieldset.default-radio-btn:focus-within {
    border: 0px !important;
    box-shadow: none;
}

fieldset:hover {
    background-color: rgba(17, 25, 39, 0.04);
}

.default-fieldset input,
.default-fieldset textarea {
    background-color: transparent !important;
}

.input-error-message,
.invalid-feedback {
    font-size: 12px;
    color: #A10F0F;
    font-weight: 500;
    text-shadow: none;
    letter-spacing: .3px;
}

.input-error-message {
    line-height: 18px;
    display: inline-block;
    margin: 4px 0 0 0;
}

.default-fieldset.InputAutoHeight {
    height: auto !important;
}

.default-fieldset-label {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 1px 4px;
    width: auto;
    margin: 0px 0 0 12px;
}

.default-fieldset .input-label-wrap {
    display: flex;
    align-items: center;
}

.default-fieldset .input-field-wrap {
    width: 18px;
    height: 18px;
    display: inline-flex;
    position: relative;
}

.default-fieldset input {
    border: 0px !important;
    box-shadow: none !important;
    border-radius: 5px;
    padding: 7px 13px 9px;
    font-family: 'Inter', sans-serif;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #111111 !important;
    line-height: normal !important;
    line-height: initial !important;
    width: 100%;
    border-radius: 5px;
    outline: none !important;
}

.default-fieldset .react-select-dealerlist input {
    width: auto;
}

.default-fieldset .select-box-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 33px;
}

.default-fieldset .react-select-dealerlist {
    flex: 1 1;
    max-width: 100%;
}

.default-fieldset .react-select-dealerlist [class*='-control'],
.default-fieldset .react-select-dealerlist [class*='-control'] * {
    border: 0 !important;
    box-shadow: none !important;
    min-height: auto;
    max-height: 33px;
    font-size: 14px;
}

.default-fieldset .react-select-dealerlist [class*="-indicatorSeparator"] {
    display: none;
}

.default-fieldset .react-select-dealerlist [class*="-indicator"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    right: 0px;
    z-index: 1;
    top: 0;
}

.default-fieldset .select-box-with-icon .dropdown-icon {
    position: absolute;
    top: 7px;
    right: 10px;
}

.default-fieldset .select-box-with-icon {
    height: 33px;
}

.default-fieldset .react-select-dealerlist [class*='-control'],
.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 33px !important;
}

.default-fieldset .react-select-dealerlist input {
    height: auto !important;
}

.default-fieldset input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
}

.default-fieldset input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
}

.default-fieldset input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757A82 !important;
    opacity: 1;
}

.default-fieldset input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #757A82 !important;
}

.default-fieldset input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #757A82 !important;
}

.default-fieldset .react-select-dealerlist input {
    max-height: 23px;
    padding: 5px 0px !important;
}

.form-row {
    align-items: flex-start;
}

/* -------------- Custom Check Box & Radio Button --------------- */

.default-fieldset.default-checkbox,
.default-fieldset.default-radio-btn {
    border: transparent;
    background: transparent !important;
}

.default-fieldset.default-checkbox .default-fieldset-label,
.default-fieldset.default-radio-btn .default-fieldset-label {
    font-weight: 400;
    padding: 0;
    font-size: 14px;
}

.default-fieldset input.form-checkbox,
.default-fieldset input.form-radio-btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.default-check-box-img,
.default-radio-btn-img,
.default-disable-check-box-img,
.default-disable-radio-box-img {
    width: 18px;
    height: 18px;
    display: inline-flex;
}

.default-disable-check-box-img,
.default-fieldset input.form-checkbox:disabled {
    cursor: auto;
}

.default-disable-check-box-img {
    background-image: url(/static/media/Checkbox-Disable.9e71fade.svg);
    background-size: cover;
}

.default-check-box-img {
    background-image: url(/static/media/CheckBox.c9101ba0.svg);
    background-size: cover;
}

.default-fieldset input.form-checkbox:checked~.default-check-box-img {
    background-image: url(/static/media/CheckBox-Active.35952080.svg);
}

.default-fieldset input.form-checkbox:checked~.default-check-box-img.default-checked-disable-check-box-img {
    background-image: url(/static/media/disabled-gray-checkbox.21600531.svg);
    background-size: cover;
}

.default-radio-btn-img {
    background-image: url(/static/media/RadioBtn.5ecc56af.svg);
    /* background-size: 18px 18px; */
    background-size: cover;
    background-position: center center;
}

.default-fieldset input.form-radio-btn:checked~.default-radio-btn-img {
    background-image: url(/static/media/RadioBtn-Active.8b2f310c.svg);
    background-size: cover;
    background-position: center center;
}

.default-disable-radio-box-img {
    background-image: url(/static/media/disable-radio-btn.3c25e640.svg);
    background-size: cover;
}

.label-icon-image {
    display: inline-flex;
    margin: 0 5px 0 10px;
}

.assignassentypes-popup .label-icon-image {
    width: 24px;
}

.label-icon-image~.default-fieldset-label {
    margin: 0;
}

.radioCheckboxLabel {
    margin-left: 6px;
}

/* -------------- Custom Check Box Ends here --------------- */

/* -------------- Date Picker Starts here --------------- */

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper .react-datepicker__close-icon {
    right: 27px;
}

.react-datepicker-wrapper .react-datepicker__close-icon::after,
.react-datepicker__month-container .react-datepicker__day--keyboard-selected,
.react-datepicker__month-container .react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__year-text--keyboard-selected {
    background-color: #243B7F;
}

.default-date-picker {
    min-width: 145px;
}

.default-date-picker .calendar-icon {
    position: absolute;
    top: 47%;
    right: 10px;
    transform: translateY(-50%);
}

.default-date-picker fieldset {
    position: relative;
    border-color: #D1D5DB;
}

.default-date-picker .react-datepicker {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 0;
}

.default-date-picker .react-datepicker__header {
    background-color: white !important;
    border: 0;
    padding: 0;
    margin: 0 0 12px 0
}

.default-date-picker .react-datepicker__current-month {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
}

.default-date-picker .react-datepicker__header__dropdown {
    margin-bottom: 10px;
}

.default-date-picker .react-datepicker__month {
    margin: 0;
}

.default-date-picker .react-datepicker__year-read-view--down-arrow {
    top: 4px;
}

.default-date-picker .react-datepicker__year-read-view--selected-year {
    margin-left: -15px;
}

.default-date-picker .react-datepicker__month-container {
    min-width: 320px;
    padding: 13px 16px 16px;
}

.default-date-picker .react-datepicker__day-names,
.default-date-picker .react-datepicker__month .react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div {
    color: #111111;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    line-height: initial;
    letter-spacing: 0.15px;
    margin: 0;
}

.default-date-picker .react-datepicker__day-names>div {
    color: rgba(55, 65, 81, 0.48);
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    margin: 0;
    width: 36px;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div {
    width: 36px;
    height: 36px;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px
}

.default-date-picker .react-datepicker__day--selected,
.default-date-picker .react-datepicker__day--keyboard-selected {
    background-color: #243B7F !important;
    color: white !important;
}

.advancedSearchFilters .react-select-dealerlist div[class$="-menu"],
.react-select-dealerlist div[class$="-menu"],
.shared-react-select div[class$="-menu"] {
    z-index: 10;
}


.react-select-dealerlist div[class$="-option"] {
    background-color: transparent !important;
    color: #111 !important;
    font-size: 14px;
}

.advancedSearchFilters .react-select-dealerlist div[class$="-menu"]>div {
    max-height: 175px;
}

.default-date-picker input {
    color: #111111;
    border-radius: 5px;
    background-color: #fff;
}

.default-date-picker .react-datepicker__triangle {
    display: none;
}

.default-date-picker .react-datepicker__navigation--previous {
    border-color: #111 !important;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
    top: 20px;
    left: 20px;
}

.default-date-picker .react-datepicker__navigation--next {
    border-color: #111 !important;
    border-width: 0 0 2px 2px;
    transform: rotate(-135deg);
    top: 20px;
    right: 20px;
}

.default-date-picker .react-datepicker__month .react-datepicker__week>div.react-datepicker__day--outside-month {
    visibility: hidden;
}

.default-date-picker .react-datepicker-popper {
    z-index: 9;
}

/* .table-filters .table-filters .filtes-calenderIcon {
    min-height: 33px;
}

.table-filters .table-filters .default-fieldset {
    min-height: 33px;
} */

.default-table .table-filters input {
    border-radius: 4px;
    background-color: #fff;
}

.default-table .table-filters .react-datepicker-wrapper input {
    background-color: #fff !important;
}

.default-table .table-filters .react-datepicker-wrapper input:hover {
    background-color: rgba(17, 25, 39, 0.04) !important;
}

.default-table .table-filters .react-datepicker-wrapper {
    /* background-color: #fff; */
    border-radius: 4px;
}

.default-date-picker input {
    background: url(/static/media/sm-calendar-icon.86ecd507.svg) no-repeat right;
    background-size: 18px;
    background-position: right 10px bottom 6px;
    min-width: 105px;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #243B7F !important;
    color: #fff !important;
}

/* -------------- Date Picker Ends here --------------- */

/* Text Area starts here */
.default-fieldset textarea.form-control {
    border: 0;
    box-shadow: none !important;
    resize: none;
    font-size: 14px;
    color: #111;
    background-image: none !important;
}

/* Text Area ends here */


/*  File upload group css start here */
.file-upload-input-group {
    position: relative;
}

.file-upload-input-group label {
    position: absolute;
    left: 10px;
    top: 8px;
    color: #9E9E9E;
    font-size: 14px;
}

.file-upload-input-group {
    display: flex;
    width: auto;
    min-width: 281px;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 10px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    overflow: hidden;
}

.file-upload-input-group input {
    opacity: 0;
}

.file-upload-input-group input::-webkit-input-placeholder {
    font-size: 14px;
}

.file-upload-input-group input:-ms-input-placeholder {
    font-size: 14px;
}

.file-upload-input-group input::placeholder {
    font-size: 14px;
}

.file-upload-input-group button {
    border: 0px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    height: 40px;
    border-left: 1px solid #D1D5DB !important;
    outline: none !important;
    box-shadow: none !important;
}

.file-upload-input-group button::before {
    border-radius: 0 !important;
}

.file-upload-input-group button:disabled {
    color: #9E9E9E !important;
    /* background-color: transparent !important; */
}

.file-upload-input-group button {
    color: #fff !important;
    background-color: #243B7F !important;
}

/*  File upload group css end here */
.input-group-styles fieldset {
    border-right: 0;
    border-radius: 4px 0 0 4px;
    flex: 1 1;
}

.input-group-styles input {
    border-radius: 5px 0 0 5px;
}

.input-group-styles .input-group-text {
    background-color: #F3F4F6;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
}

.input-group-styles input[disabled] {
    border-radius: 4px 0 0 4px;
}

.input-group-styles .invalid-feedback {
    order: 1;
}

.group-label .input-group-text {
    margin-top: 7px;
}

.custom-switch .custom-control-label::before {
    width: 22px;
    box-shadow: none !important;
    outline: none !important;
    background-color: #fff !important;
    border-color: #9E9E9E !important;
    cursor: pointer;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    left: auto;
    right: 15px;
    transform: none;
}

input {
    background-image: none !important;
}

.react-select-dealerlist input,
.partnerACHCreditTransaction table .form-check-input.position-static {
    position: relative !important;
}

.react-select-dealerlist input[checked]::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(/static/media/CheckBox-Active.35952080.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist input::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(/static/media/CheckBox.c9101ba0.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist div[class$="-menu"] input:checked::after,
.partnerACHCreditTransaction table td:first-child input:checked::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(/static/media/CheckBox-Active.35952080.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 2px !important;
}

.react-select-dealerlist div[class$="-menu"] input::after,
.partnerACHCreditTransaction table td:first-child input::after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(/static/media/CheckBox.c9101ba0.svg) !important;
    background-size: cover !important;
    left: 0 !important;
    top: 00% !important;
    border-radius: 2px !important;
    cursor: pointer;
}

.react-select-dealerlist div[class$="-multiValue"] div:nth-child(2):hover {
    background-color: #A10F0F !important;
    color: #fff;
    cursor: pointer;
}

.cust-multi-select-box .select-box-with-icon,
.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: 33px !important;
    height: auto;
    background-color: transparent !important;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control']~div {
    z-index: 5;
}

.multi-select-box-height.cust-multi-select-box .select-box-with-icon,
.multi-select-box-height.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'],
.multi-select-box-height.cust-multi-select-box.default-fieldset .react-select-dealerlist [class*='-control'] * {
    max-height: unset !important;
    height: auto;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class$='-multiValue'] {
    background-color: #e6e6e6 !important;
    max-width: 95%;
}


/* Multi Select Close Icon Start */

.cust-multi-select-box .react-select-dealerlist [class*="-indicator"]:first-child {
    right: 30px;
    opacity: 0;
    top: 0;
}

.cust-multi-select-box.default-fieldset .react-select-dealerlist [class$="-control"]>div:nth-child(2) {
    width: 58px;
}

.cust-multi-select-box .close-icon-clearable {
    position: absolute;
    right: 43px;
    top: 4px;
}

.cust-multi-select-box .close-icon-clearable::after {
    content: '';
    height: 20px;
    width: 1px;
    display: block;
    background-color: #9E9E9E;
    position: absolute;
    right: -9px;
    top: 3px;
}

.cust-multi-select-box .react-select-dealerlist [class*="-option"]:hover {
    background-color: #F3F4F6 !important;
}

.select-white-bg {
    background-color: white;
}

.multi-selected-option {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cust-multi-select-box.dropdown-height div[class$="-menu"]>div {
    max-height: 135px;
}

.default-table .table-filters .react-table-datalist-selector [class$="-control"] {
    border: 1px solid #e0e0e0 !important;
}

.default-table .table-filters .react-table-datalist-selector:focus-within {
    outline: 2px solid #243B7F !important;
    border-radius: 4px;
}

/* Multi Select Close Icon End */

/* All input css end here  */


@media (max-width: 1599px) {

    .default-fieldset.default-checkbox .default-fieldset-label,
    .default-fieldset.default-radio-btn .default-fieldset-label {
        font-size: 12px;
    }

    /* .login-page-wrapper .default-fieldset {
        margin: 0px 0 13px 0;
    } */


    .table-filters .default-fieldset input {
        /* font-size: 10px !important; */
    }

    .default-table .default-fieldset .default-fieldset-label {
        font-size: 12px;
    }

    .default-fieldset.default-checkbox,
    .default-fieldset.default-radio-btn {
        margin: 0px 0px 6px 0px;
    }

    .default-fieldset.default-checkbox .input-field-wrap,
    .default-fieldset.default-radio-btn .input-field-wrap,
    .default-check-box-img,
    .default-radio-btn-img,
    .default-disable-check-box-img,
    .default-disable-radio-box-img {
        width: 14px;
        height: 14px;
    }

    .default-fieldset input,
    .default-fieldset .react-select-dealerlist [class*='-control'],
    .default-fieldset .react-select-dealerlist [class*='-control'] *,
    .react-select-dealerlist div[class$="-option"],
    .default-fieldset textarea.form-control {
        font-size: 12px !important;
    }

    .default-date-picker {
        min-width: 135px;
    }

    .default-date-picker .react-datepicker__current-month {
        font-size: 14px;
    }

    .default-date-picker .react-datepicker__month-container {
        min-width: 230px;
        padding: 6px 8px 8px;
    }

    .default-date-picker .react-datepicker__header {
        margin: 0 0 8px 0;
    }

    .default-date-picker .react-datepicker__header__dropdown {
        margin-bottom: 5px;
    }

    .default-date-picker .react-datepicker__month .react-datepicker__week>div {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }

    .default-date-picker .react-datepicker__day-names>div {
        width: auto;
        text-align: center;
        min-width: 15px;
    }

}
